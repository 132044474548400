import {
  addYears,
  differenceInDays,
  format,
  isSameYear,
  parseISO,
  subDays,
  subMonths,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  endOfQuarter,
  subQuarters,
} from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { EacUnits } from "@/services/api/certificate.model"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

export enum LoadshapeInterval {
  monthly = "monthly",
  daily = "daily",
  hourly = "hourly",
  timeOfDay = "timeOfDay",
}

export const LOADSHAPE_INTERVAL: Record<LoadshapeInterval, string> = {
  monthly: "Month",
  daily: "Day",
  hourly: "Hour (asset local time)",
  timeOfDay: "Hour of the day (asset local time)",
}

export enum LoadshapeEacSource {
  minted_eacs = "minted_eacs",
  provisional_eacs = "provisional_eacs",
}

export const LOADSHAPE_EAC_SOURCE: Record<LoadshapeEacSource, string> = {
  minted_eacs: "Minted EACs",
  provisional_eacs: "Provisional EACs",
}

const formattedHourMapping = [
  "12-1 AM",
  "1-2 AM",
  "2-3 AM",
  "3-4 AM",
  "4-5 AM",
  "5-6 AM",
  "6-7 AM",
  "7-8 AM",
  "8-9 AM",
  "9-10 AM",
  "10-11 AM",
  "11-12 AM",
  "12-1 PM",
  "1-2 PM",
  "2-3 PM",
  "3-4 PM",
  "4-5 PM",
  "5-6 PM",
  "6-7 PM",
  "7-8 PM",
  "8-9 PM",
  "9-10 PM",
  "10-11 PM",
  "11-12 PM",
]

export type APIAssetLoadshapeRow = {
  deviceLocalDatetime: string
  values: {
    quantity: number
    units: EacUnits
    netGco2eEmitted: number
    comparisonQuantity: number | null
    comparisonNetGco2eEmitted: number | null
  }[]
}

export type AssetLoadshapeRowValue = {
  quantity: number
  units: EacUnits
  netGco2eEmitted: number
  comparisonQuantity: number | null
  comparisonNetGco2eEmitted: number | null
}

const addNullable = (a: number | null, b: number | null): number | null => {
  return a !== null && b !== null ? a + b : null
}

export const flipSigns = (rows: AssetLoadshapeRow[]): AssetLoadshapeRow[] => {
  return rows.map((row) => {
    row.values = row.values.map((value) => ({
      ...value,
      quantity: -value.quantity,
      netGco2eEmitted: -value.netGco2eEmitted,
      comparisonQuantity: value.comparisonQuantity !== null ? -value.comparisonQuantity : null,
      comparisonNetGco2eEmitted: value.comparisonNetGco2eEmitted !== null ? -value.comparisonNetGco2eEmitted : null,
    }))
    return row
  })
}

const reduceNullable = <T extends { [K in P]: number | null }, P extends string>(values: T[], key: P): number | null => {
  if (values.length === 0) {
    return null
  }
  return values.reduce((acc: number | null, v) => addNullable(acc, v[key]), 0)
}

export class AssetLoadshapeRow {
  datetime: Date
  values!: AssetLoadshapeRowValue[]

  constructor({ deviceLocalDatetime, values }: APIAssetLoadshapeRow) {
    this.values = values
    this.datetime = parseUTCTimestamp(deviceLocalDatetime)
  }

  private findValueByUnit(unit: EacUnits): AssetLoadshapeRowValue | undefined {
    return this.values.find((v) => v.units === unit)
  }

  public get Hour(): number {
    return this.datetime.getHours()
  }

  public get HourLabel(): string {
    return format(this.datetime, "MM/dd/yy HH:mm")
  }

  public get UTCHourLabel(): string {
    return formatInTimeZone(this.datetime, "UTC", "MM/dd/yy HH:mm")
  }

  public get DayLabel(): string {
    return format(this.datetime, "MM/dd/yy")
  }

  public get UTCDayLabel(): string {
    return formatInTimeZone(this.datetime, "UTC", "MM/dd/yy")
  }

  public get MonthLabel(): string {
    return format(this.datetime, "MMM yyyy")
  }

  public get UTCMonthLabel(): string {
    return formatInTimeZone(this.datetime, "UTC", "MMM yyyy")
  }

  public get TimeOfDayLabel(): string {
    return formattedHourMapping[this.datetime.getUTCHours()] ?? ""
  }

  public get SuppliedElectricityWattHours(): number | null {
    return this.findValueByUnit(EacUnits.wh_electricity_supplied)?.quantity || null
  }

  public get ComparisonSuppliedElectricityWattHours(): number | null {
    return this.findValueByUnit(EacUnits.wh_electricity_supplied)?.comparisonQuantity || null
  }

  public get ConsumedElectricityWattHours(): number | null {
    return this.findValueByUnit(EacUnits.wh_electricity_consumed)?.quantity || null
  }

  public get ComparisonConsumedElectricityWattHours(): number | null {
    return this.findValueByUnit(EacUnits.wh_electricity_consumed)?.comparisonQuantity || null
  }

  public get NetCarbonEmittedGrams(): number | null {
    const total = reduceNullable(this.values, "netGco2eEmitted")
    return total === null ? null : total * -1
  }

  public get ComparisonNetCarbonEmittedGrams(): number | null {
    const total = reduceNullable(this.values, "comparisonNetGco2eEmitted")
    return total === null ? null : total * -1
  }

  public get NetCarbonEmittedKilograms(): number | null {
    if (this.NetCarbonEmittedGrams === null) {
      return null
    }
    return this.NetCarbonEmittedGrams / 1000
  }

  public get ComparisonNetCarbonEmittedKilograms(): number | null {
    if (this.ComparisonNetCarbonEmittedGrams === null) {
      return null
    }
    return this.ComparisonNetCarbonEmittedGrams / 1000
  }

  public get NetElectricityWattHours(): number | null {
    if (this.SuppliedElectricityWattHours === null && this.ConsumedElectricityWattHours === null) {
      return null
    } else if (this.SuppliedElectricityWattHours !== null) {
      return this.SuppliedElectricityWattHours
    } else {
      return this.ConsumedElectricityWattHours! * -1
    }
  }

  public get ComparisonNetElectricityWattHours(): number | null {
    if (this.ComparisonSuppliedElectricityWattHours === null && this.ComparisonConsumedElectricityWattHours === null) {
      return null
    } else if (this.ComparisonSuppliedElectricityWattHours !== null) {
      return this.ComparisonSuppliedElectricityWattHours
    } else {
      return this.ComparisonConsumedElectricityWattHours! * -1
    }
  }

  public get NetElectricityKilowattHours(): number | null {
    if (this.NetElectricityWattHours === null) {
      return null
    }
    return this.NetElectricityWattHours / 1000
  }

  public get ComparisonNetElectricityKilowattHours(): number | null {
    if (this.ComparisonNetElectricityWattHours === null) {
      return null
    }
    return this.ComparisonNetElectricityWattHours / 1000
  }

  public get NetElectricityMegawattHours(): number | null {
    if (this.NetElectricityWattHours === null) {
      return null
    }
    return this.NetElectricityWattHours / 1_000_000
  }

  public get ComparisonNetElectricityMegawattHours(): number | null {
    if (this.ComparisonNetElectricityWattHours === null) {
      return null
    }
    return this.ComparisonNetElectricityWattHours / 1_000_000
  }

  public get CarbonIntensityKgPerMWh(): number | null {
    if (this.NetElectricityMegawattHours === 0 || this.NetCarbonEmittedKilograms === null || this.NetElectricityMegawattHours === null) {
      return null
    }
    return this.NetCarbonEmittedKilograms / this.NetElectricityMegawattHours
  }

  public get ComparisonCarbonIntensityKgPerMWh(): number | null {
    if (
      this.ComparisonNetElectricityMegawattHours === 0 ||
      this.ComparisonNetCarbonEmittedKilograms === null ||
      this.ComparisonNetElectricityMegawattHours === null
    ) {
      return null
    }
    return this.ComparisonNetCarbonEmittedKilograms / this.ComparisonNetElectricityMegawattHours
  }
}

export const convertToUTCTimeOfDay = (entries: APIAssetLoadshapeRow[]) => {
  // Initialize array with 24 empty hour buckets
  const hourBuckets = Array.from({ length: 24 }, (_, i) => ({
    deviceLocalDatetime: `2024-01-01T${i.toString().padStart(2, "0")}:00:00`,
    values: [] as (typeof entries)[0]["values"],
  }))

  return entries.reduce((acc, entry) => {
    const hour = parseUTCTimestamp(entry.deviceLocalDatetime).getUTCHours()

    entry.values.forEach((value) => {
      const existingValue = acc[hour].values.find((v) => v.units === value.units)

      if (existingValue) {
        existingValue.quantity += value.quantity
        existingValue.netGco2eEmitted += value.netGco2eEmitted
        existingValue.comparisonQuantity = addNullable(existingValue.comparisonQuantity, value.comparisonQuantity)
        existingValue.comparisonNetGco2eEmitted = addNullable(existingValue.comparisonNetGco2eEmitted, value.comparisonNetGco2eEmitted)
      } else {
        acc[hour].values.push({ ...value })
      }
    })

    return acc
  }, hourBuckets)
}

export const convertToMonthly = (entries: APIAssetLoadshapeRow[]) => {
  return Object.values(
    entries.reduce(
      (acc, entry) => {
        const yearMonth = entry.deviceLocalDatetime.substring(0, 7)

        if (!acc[yearMonth]) {
          acc[yearMonth] = {
            deviceLocalDatetime: `${yearMonth}T00:00:00`,
            values: [],
          }
        }

        entry.values.forEach((value) => {
          const existingValue = acc[yearMonth].values.find((v) => v.units === value.units)

          if (existingValue) {
            existingValue.quantity += value.quantity
            existingValue.netGco2eEmitted += value.netGco2eEmitted
            existingValue.comparisonQuantity = addNullable(existingValue.comparisonQuantity, value.comparisonQuantity)
            existingValue.comparisonNetGco2eEmitted = addNullable(existingValue.comparisonNetGco2eEmitted, value.comparisonNetGco2eEmitted)
          } else {
            acc[yearMonth].values.push({ ...value })
          }
        })

        return acc
      },
      {} as Record<string, APIAssetLoadshapeRow>
    )
  )
}

export const convertToDaily = (entries: APIAssetLoadshapeRow[]) => {
  return Object.values(
    entries.reduce(
      (acc, entry) => {
        const date = entry.deviceLocalDatetime.split("T")[0]

        if (!acc[date]) {
          acc[date] = {
            deviceLocalDatetime: `${date}T00:00:00`,
            values: [],
          }
        }

        entry.values.forEach((value) => {
          const existingValue = acc[date].values.find((v) => v.units === value.units)

          if (existingValue) {
            existingValue.quantity += value.quantity
            existingValue.netGco2eEmitted += value.netGco2eEmitted
            existingValue.comparisonQuantity = addNullable(existingValue.comparisonQuantity, value.comparisonQuantity)
            existingValue.comparisonNetGco2eEmitted = addNullable(existingValue.comparisonNetGco2eEmitted, value.comparisonNetGco2eEmitted)
          } else {
            acc[date].values.push({ ...value })
          }
        })

        return acc
      },
      {} as Record<string, APIAssetLoadshapeRow>
    )
  )
}

export const makePresetDateRanges = () => {
  const today = new Date()
  const currentQuarterStart = startOfQuarter(today)

  // Get three previous quarters
  const quarterOffsets = [3, 2, 1].map((quartersBack) => {
    const start = startOfQuarter(subQuarters(currentQuarterStart, quartersBack))
    const end = endOfQuarter(subQuarters(currentQuarterStart, quartersBack))
    const quarterNumber = Math.floor(start.getMonth() / 3) + 1

    return {
      label: `Q${quarterNumber} ${start.getFullYear()}`,
      range: [start, end],
    }
  })

  // Add the current quarter
  const currentQuarter = {
    label: `Q${Math.floor(currentQuarterStart.getMonth() / 3) + 1} ${currentQuarterStart.getFullYear()}`,
    range: [currentQuarterStart, endOfQuarter(today)],
  }

  const quarters = [...quarterOffsets, currentQuarter]

  const presets = {
    "Last Year": [startOfYear(subDays(today, 365)), startOfYear(today)],
    "This Year": [startOfYear(today), today],
    "This Month": [startOfMonth(today), today],
    "This Week": [startOfWeek(today), today],
    "Last 12 Months": [subMonths(today, 12), today],
    "Last 30 Days": [subDays(today, 30), today],
    "Last 7 days": [subDays(today, 7), today],
    ...Object.fromEntries(quarters.map((q) => [q.label, q.range])),
  }
  return presets
}

export const dateRangePresetGivenAvailableDataRange = (availableDataRange: { lower: string | null; upper: string | null }) => {
  const lowerDate = parseISO(availableDataRange.lower ?? "")
  const upperDate = parseISO(availableDataRange.upper ?? "")

  // Available data is all within the current year, so show the current year so far
  if (isSameYear(lowerDate, upperDate) && isSameYear(lowerDate, new Date())) {
    return [startOfYear(lowerDate), new Date()]
  }

  // Available data is all within a given year, so show that year
  if (isSameYear(lowerDate, upperDate) && !isSameYear(lowerDate, new Date())) {
    return [startOfYear(lowerDate), startOfYear(addYears(lowerDate, 1))]
  }

  // Available data spans years, but the range is not greater than 366 days, so show the full range
  if (differenceInDays(upperDate, lowerDate) <= 366) {
    return [lowerDate, upperDate]
  }

  // Available data spans more days than we can show at once, so show the most recent 366 days
  return [subDays(upperDate, 366), upperDate]
}
