<template>
  <div class="mx-auto flex min-h-[600px] max-w-6xl flex-col rounded-md bg-white shadow-md 2xl:max-w-[1800px]">
    <div class="w-full rounded-t-md bg-blue-99 p-2 pt-4 text-white">
      <h1 class="text-subheading-1">Control Room ⚡</h1>
    </div>

    <div class="flex flex-col 2xl:flex-row 2xl:justify-stretch">
      <div class="flex-1 px-3">
        <div class="-mx-3 bg-divider-sagedark p-2 pt-4 text-white">
          <h2 class="text-subheading-2">M&V Overview</h2>
        </div>

        <table class="my-3 w-full">
          <colgroup span="1"></colgroup>
          <colgroup span="3" class="border-r border-sagetone"></colgroup>
          <colgroup span="3" class="border-r border-sagetone"></colgroup>
          <colgroup span="2" class="border-r border-sagetone"></colgroup>
          <colgroup span="2"></colgroup>

          <thead class="border-b border-sagetone">
            <tr>
              <th></th>
              <th colspan="3">Assets by Status</th>
              <th colspan="3">Assets by Audit Status</th>
              <th colspan="2">Jobs</th>
              <th colspan="2">Billing</th>
            </tr>
            <tr>
              <th class="text-left">Account</th>

              <th class="text-right">Draft</th>
              <th class="text-right">Ready</th>
              <th class="pr-2 text-right">Archived</th>

              <th class="text-right">Pending</th>
              <th class="text-right">Failed</th>
              <th class="pr-2 text-right">Passed</th>

              <th class="text-right">Last Audit</th>
              <th class="pr-2 text-right">Last Mint</th>

              <th class="text-right">Pending</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="accountSummaries.length == 0 && isLoadingAccountSummaries">
              <tr v-for="i in 10" :key="i">
                <td colspan="10" class="py-2"><Skeleton height="1.5em" width="100%" /></td>
              </tr>
            </template>
            <template v-else-if="accountSummaryError">
              <tr>
                <td colspan="10">
                  <InlineError :error-message="accountSummaryError" />
                </td>
              </tr>
            </template>
            <tr v-for="summary in accountSummaries" :key="summary.account.id" class="even:bg-highlight-pastelazure">
              <td class="max-w-[20ch] truncate py-2 text-left underline">
                <router-link :to="{ name: 'wc-admin-account-audit-summary', params: { accountId: summary.account.id } }">
                  {{ summary.account.name }}
                </router-link>
              </td>

              <td class="py-2 text-right">{{ prettyNumber(summary.devices.statuses.draft) }}</td>
              <td class="py-2 text-right">{{ prettyNumber(summary.devices.statuses.ready) }}</td>
              <td class="py-2 pr-2 text-right">{{ prettyNumber(summary.devices.statuses.archived) }}</td>

              <td class="py-2 text-right">{{ prettyNumber(summary.audits.statuses.pending + summary.audits.statuses.running) }}</td>
              <td class="py-2 text-right">{{ prettyNumber(summary.audits.statuses.failed) }}</td>
              <td class="py-2 pr-2 text-right">{{ prettyNumber(summary.audits.statuses.passed) }}</td>

              <td class="py-2 text-right">{{ summary.latestJobs.auditing ? formatDate(summary.latestJobs.auditing) : "—" }}</td>
              <td class="py-2 pr-2 text-right" :class="hasUnmintedEacs(summary) ? 'text-error' : ''">
                {{ summary.latestJobs.minting ? formatDate(summary.latestJobs.minting) : "—" }}
              </td>

              <td class="py-2 text-right underline">
                <router-link :to="{ name: 'wc-admin-account-billing', params: { accountId: summary.account.id } }">
                  {{ formatCurrencyInPennies(summary.billing.penniesUsd) }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex-1 border-blue-99 px-3 2xl:border-l-4">
        <div class="-mx-3 bg-divider-sagedark p-2 pt-4 text-white">
          <h2 class="text-subheading-2">Recent Jobs</h2>
        </div>

        <table class="my-3 w-full">
          <thead>
            <tr>
              <th class="pl-6 text-left">Status</th>
              <th class="pl-6 text-left">Job</th>
              <th class="text-left">Last Update</th>
              <th class="text-left">Target</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="jobsToRender.length == 0 && isLoadingJobs">
              <tr v-for="i in 10" :key="i">
                <td colspan="4" class="py-2"><Skeleton height="1.5em" width="100%" /></td>
              </tr>
            </template>
            <template v-else-if="jobsError">
              <tr>
                <td colspan="4">
                  <InlineError :error-message="jobsError" />
                </td>
              </tr>
            </template>
            <tr v-if="queuedJobs.length > PEEK_COUNT" :class="extraJobClasses('queued')">
              <td class="align-top">
                <div class="flex flex-col">
                  <div class="flex items-center">
                    <JobStatusIcon status="queued" />
                    <span class="capitalize">Queue</span>
                  </div>
                  <div class="pl-6 text-sm text-sagetone"></div>
                </div>
              </td>
              <td colspan="3" class="pb-6 pl-6 align-top">{{ queuedJobs.length - PEEK_COUNT }} jobs</td>
            </tr>
            <tr v-for="job in jobsToRender" :key="job.id" class="even:bg-highlight-pastelazure" :class="extraJobClasses(job.status)">
              <td class="align-top">
                <div class="flex flex-col">
                  <div class="flex items-center">
                    <JobStatusIcon :status="job.status" />
                    <span class="capitalize">{{ job.status === "queued" ? "Next" : job.status }}</span>
                  </div>
                  <div class="pl-6 text-sm text-sagetone">{{ job.message }}</div>
                </div>
              </td>
              <td class="align-top capitalize">
                <div class="flex items-center">
                  <JobKindIcon :kind="job.kind" />
                  {{ formatPossibleSnakeCaseStringForDisplay(job.kind) }}
                </div>
              </td>
              <td class="align-top">
                <RelativeTime :time="job.timestamp" />
              </td>
              <td class="pb-2">
                <div class="flex flex-col">
                  <div class="text-left underline">
                    <router-link v-if="job.entity.kind == 'device'" :to="{ name: 'wc-admin-asset-metadata', params: { assetId: job.entity.id } }">
                      {{ job.entity.name ?? `Asset #${job.entity.id}` }}
                    </router-link>
                    <template v-else-if="job.entity.kind == 'data_provider'">Data Provider #{{ job.entity.id }}</template>
                    <template v-else-if="job.entity.kind == 'meter'">Meter #{{ job.entity.id }}</template>
                  </div>

                  <div class="text-sm text-sagetone">
                    <router-link :to="{ name: 'wc-admin-account-audit-summary', params: { accountId: job.entity.account.id } }">
                      {{ job.entity.account.name }}
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue"
import { formatDate } from "@/utils/formatDate"
import { adminGetAccounts, jobsListJobs } from "@/client/sdk.gen"
import { AccountSummary, Job } from "@/client/types.gen"
import type { JobStatus } from "@/client/types.gen"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"
import { usePolling } from "@/composables/usePolling"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"
import { formatPossibleSnakeCaseStringForDisplay } from "@/utils/formatPossibleSnakeCaseStringForDisplay"
import InlineError from "@/components/ui/InlineError.vue"
import Skeleton from "primevue/skeleton"
import JobStatusIcon from "./JobStatusIcon.vue"
import JobKindIcon from "./JobKindIcon.vue"
import RelativeTime from "@/components/ui/RelativeTime.vue"

const formatCurrencyInPennies = formatCurrencyInPenniesFactory()

const isLoadingAccountSummaries = ref(true)
const accountSummaryError = ref<string | null>(null)
const accountSummaries = ref<AccountSummary[]>([])
const loadAccountSummaries = async () => {
  try {
    isLoadingAccountSummaries.value = true
    const response = await adminGetAccounts()
    accountSummaries.value = response.data
  } catch (error: any) {
    accountSummaryError.value = error?.detail ?? "Unknown error"
  } finally {
    isLoadingAccountSummaries.value = false
  }
}

const isLoadingJobs = ref(true)
const jobsError = ref<string | null>(null)
const jobs = ref<Job[]>([])
const loadJobs = async () => {
  try {
    isLoadingJobs.value = true
    const response = await jobsListJobs({
      query: {
        per_page: 100,
      },
    })
    jobs.value = response.data
  } catch (error: any) {
    jobsError.value = error?.detail ?? "Unknown error"
  } finally {
    isLoadingJobs.value = false
  }
}

const PEEK_COUNT = 1

const queuedJobs = computed(() => {
  return jobs.value.filter((j) => j.status === "queued")
})

const jobsToRender = computed(() => {
  const upcoming = queuedJobs.value.slice(-PEEK_COUNT, queuedJobs.value.length)

  const running = jobs.value.filter((j) => j.status === "running")
  const done = jobs.value.filter((j) => ["complete", "failed"].includes(j.status)).slice(0, 10)

  return [...upcoming, ...running, ...done]
})

const extraJobClasses = (status: JobStatus) => {
  switch (status) {
    case "queued":
      return ["text-neutral-50"]
    case "running":
      return ["italic"]
    case "complete":
    case "failed":
      return []
  }
}

const { startPolling } = usePolling(
  async () => {
    await Promise.all([loadAccountSummaries(), loadJobs()])
  },
  { frequency: 5000, maxPollCount: null, immediate: true }
)

onMounted(() => {
  startPolling()
})

const hasUnmintedEacs = (summary: AccountSummary) => {
  if (!summary.latestJobs.auditing) return false
  if (!summary.latestJobs.minting) return true
  return parseUTCTimestamp(summary.latestJobs.minting) < parseUTCTimestamp(summary.latestJobs.auditing)
}

const prettyNumber = (num: number) => {
  if (num === 0) return ""
  return num.toLocaleString()
}
</script>
