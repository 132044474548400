<template>
  <div class="flex w-full flex-col gap-12 lg:flex-row lg:items-end lg:gap-4">
    <AssetEnergyOverTimeContainer
      v-if="asset && summary"
      v-show="!hasError"
      class="grow"
      :is-loading="isLoading"
      :rows="(deviceLoadshape?.rows as APIAssetLoadshapeRow[]) ?? []"
      :summary="summary"
      :asset-kind="asset.kind"
      @load-rows="handleLoadRows" />
    <div class="mb-5 flex min-w-0 shrink-0 gap-4 max-md:flex-wrap lg:basis-[272px] lg:flex-col lg:gap-2">
      <div
        v-for="metric in metrics"
        :key="metric.title"
        class="rounded-xl border border-sagetone-dark bg-darktone-lightbackground p-5 max-lg:w-1/3 max-sm:w-full">
        <div class="text-body-3 mb-2">{{ metric.title }}</div>
        <p class="text-subheading-large-bold">
          <template v-if="metric.value">
            {{ metric.value.quantity }}
            <span class="text-body-3">{{ metric.value.unit }}</span>
          </template>
          <template v-else>N/A</template>
        </p>
      </div>
    </div>
  </div>
  <InlineError v-if="hasError" error-message="There was a problem loading the loadshape for this asset. Please try again." />
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { DeviceLoadshape } from "@/client/types.gen"
import type { Asset } from "@/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import InlineError from "@/components/ui/InlineError.vue"
import type { AssetSummary, LoadshapeRequestOptions } from "@/modules/asset/asset.service"
import type { APIAssetLoadshapeRow } from "@/modules/asset/assetLoadshape.model"
import { useAssetService } from "@/services/service-container"
import AssetEnergyOverTimeContainer from "./AssetEnergyOverTimeContainer.vue"

const assetService = useAssetService()

const props = defineProps<{ asset: Asset; summary?: AssetSummary }>()

const metrics = computed(() => {
  return [
    {
      title: "Rolling daily average (last 30 days)",
      value:
        deviceLoadshape.value?.rollingDailyAverage != null
          ? getFormattedEacQuantity(deviceLoadshape.value.rollingDailyAverage, EacMeasurementParameter.GhgEmissions)
          : null,
    },
    {
      title: "Rolling monthly average (last 12 months)",
      value:
        deviceLoadshape.value?.rollingMonthlyAverage != null
          ? getFormattedEacQuantity(deviceLoadshape.value.rollingMonthlyAverage, EacMeasurementParameter.GhgEmissions)
          : null,
    },
    {
      title: "YTD (+/- from last year)",
      value:
        deviceLoadshape.value?.ytdVsLastYear != null
          ? getFormattedEacQuantity(deviceLoadshape.value.ytdVsLastYear, EacMeasurementParameter.GhgEmissions)
          : null,
    },
  ]
})

const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)
const deviceLoadshape = ref<DeviceLoadshape | null>(null)

const handleLoadRows = async (options: LoadshapeRequestOptions) => {
  isLoading.value = true
  try {
    deviceLoadshape.value = await assetService.getAssetLoadshape(props.asset.id, options)
    hasError.value = false
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset loadshape", error)
  }
  isLoading.value = false
}
</script>
