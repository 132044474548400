<template>
  <div class="relative">
    <Dropdown
      v-model="model"
      option-label="label"
      :options="options"
      :option-group-label="grouped ? 'label' : undefined"
      :option-group-children="grouped ? 'items' : undefined"
      :placeholder="insetLabel"
      :name="name"
      class="w-full"
      :class="inputClass"
      :panel-class="inputClass"
      :editable="editable"
      scroll-height="400px"
      :show-clear="!required"
      append-to="self">
      <template v-if="insetLabel" #header>
        <h4 class="text-subheading-1 mx-5 mb-1 mt-5">{{ insetLabel }}</h4>
      </template>
    </Dropdown>
    <!-- Hidden input for form validation -->
    <input
      v-if="required"
      type="text"
      :name="name"
      :required="required"
      class="pointer-events-none absolute left-0 opacity-0"
      :value="model?.value ?? ''"
      tabindex="-1" />
  </div>
</template>

<script setup lang="ts" generic="T">
import Dropdown from "primevue/dropdown"
import type { SelectOption, SelectOptionGroup } from "./WcDropdown"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

const model = defineModel<SelectOption<T> | null>()
const props = defineProps<{
  insetLabel?: string
  required?: boolean
  options: SelectOption<T>[] | SelectOptionGroup<T>[]
  grouped?: boolean
  name: string
  editable?: boolean
  size?: InputSize
}>()

const inputClass = useInputClass(props)
</script>
