<template>
  <AppPage>
    <AppPageHeader> Meters </AppPageHeader>
    <AppPageContent>
      <AppPageSection>
        <div v-if="meters.length > 0 || searchQuery || isLoading" class="flex flex-wrap items-end justify-between gap-2">
          <WcInputText
            v-model="searchQuery"
            icon="wc-carbon:search"
            class="shrink-0 basis-48"
            name="search-meters"
            inset-label="Search"
            size="small" />
        </div>

        <InlineLoading v-if="isLoading" />
        <InlineError v-else-if="hasError" error-message="There was a problem loading meters. Please try again." />
        <div v-else-if="meters.length > 0">
          <MetersTable :meters="meters" show-data-provider />
          <PaginationButtons class="mt-3" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadMeters" />
        </div>
        <EmptyState v-else-if="!searchQuery" title="No meters found">
          <a href="#" class="text-hyperlink" @click="addDataProviderModal.openModal()">Connect a data provider</a> to import meters into your account.
        </EmptyState>
      </AppPageSection>
    </AppPageContent>
    <AddDataProviderModal ref="addDataProviderModal" />
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { WcInputText } from "@/components/input"
import { AppPage, AppPageContent, AppPageHeader, AppPageSection } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { metersListMetersPaginated } from "@/client/sdk.gen"
import type { Meter } from "@/client/types.gen"
import AddDataProviderModal from "@/modules/dataProvider/components/AddDataProviderModal.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { debounce } from "@/utils/debounce"
import MetersTable from "./components/MetersTable.vue"

const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)
const meters = ref<Meter[]>([])
const pageInfo = ref<PageInfo>()
const addDataProviderModal = ref()
const searchQuery = ref<string>("")

const loadMeters = async (url?: string, page?: string | null) => {
  hasError.value = false
  isLoading.value = true

  try {
    const result = await metersListMetersPaginated({
      query: {
        per_page: 10,
        page,
        ...(searchQuery.value ? { q: searchQuery.value } : {}),
      },
    })

    meters.value = result.data
    pageInfo.value = result.pageInfo
  } catch (error) {
    hasError.value = true
    console.error("Error loading meters", error)
  } finally {
    isLoading.value = false
  }
}

const debouncedLoadMeters = debounce(() => {
  loadMeters()
})

watch([searchQuery], () => {
  if (searchQuery.value === "") {
    loadMeters()
  } else {
    debouncedLoadMeters()
  }
})

onMounted(async () => {
  await loadMeters()
})
</script>
