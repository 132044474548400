<template>
  <div class="max-w-[1144px]">
    <h2 class="text-subheading-large-bold mb-4">Model enhancement</h2>
    <p class="text-body-2 mb-12">Help Aristotle build a better version of your reference model by providing more details</p>

    <InlineLoading v-if="!componentPointer || !formSchema" />

    <div v-else>
      <form ref="assetFormRef" class="flex flex-col space-y-6" @submit.prevent="handleSubmit">
        <!-- Optional fields for all other methodology assets -->
        <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">
          <WcOpenApiFormFields v-model="formValue" :form="optionalFormSchema" :hide="HIDDEN_FORM_FIELDS" :errors="fieldErrors" />
        </div>

        <div v-if="generalErrors.length > 0" class="mb-4 rounded-md border border-error bg-error-light p-4">
          <h5 class="text-subheading-2 mb-2 text-error">Form Validation Errors</h5>
          <ul class="list-disc space-y-1 pl-5">
            <li v-for="generalError in generalErrors" :key="generalError" class="text-error">{{ generalError }}</li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import type { Meter } from "@/client/types.gen"
import { useOpenApiStore } from "@/components/form/openapi.state"
import { ApiValidationError, prepareForm, parseApiErrors } from "@/components/form/WcOpenApiForm.utils"
import WcOpenApiFormFields from "@/components/form/WcOpenApiFormFields.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import type { Methodology } from "@/modules/asset/asset.service"

const HIDDEN_FORM_FIELDS = ["name", "customId", "kind", "groupId", "groupName", "location", "meterIds"]

type AssetData = Record<string, any>

const props = defineProps<{
  modelValue: AssetData
  selectedMethodology: Methodology | null
  selectedMeters: Meter[]
  error: any
}>()

const emit = defineEmits<{
  (e: "update:modelValue", value: AssetData): void
  (e: "submit", value: AssetData): void
}>()

const openApiStore = useOpenApiStore()
const assetFormRef = ref<HTMLFormElement | null>(null)
const fieldErrors = ref<ApiValidationError[]>([])
const generalErrors = ref<string[]>([])

const formValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value)
  },
})

const componentPointer = computed(() => {
  if (!props.selectedMethodology) {
    return null
  }

  const discriminator = openApiStore.dereferenceUri("#/paths/~1devices/post/requestBody/content/application~1json/schema/discriminator")
  if (!discriminator) {
    return null
  }
  return discriminator.mapping[props.selectedMethodology.kind]
})

const formSchema = computed(() => {
  if (!componentPointer.value) {
    return null
  }
  const baseSchema = prepareForm(openApiStore, componentPointer.value)
  return baseSchema
})

const optionalFormSchema = computed(() => {
  if (!formSchema.value?.fields) {
    return {
      type: "form" as const,
      name: "",
      title: "",
      fields: [],
    }
  }

  return {
    ...formSchema.value,
    fields: formSchema.value.fields.filter((field) => !field.required),
  }
})

const handleSubmit = (event: Event) => {
  const form = event.target as HTMLFormElement

  if (form && !form.checkValidity()) {
    form.reportValidity()
    return
  }

  emit("submit", formValue.value)
}

// Watch for error changes and update the local error state
watch(
  () => props.error,
  () => {
    const parsedErrors = props.error ? parseApiErrors(props.error) : { fieldErrors: [], generalErrors: [] }
    fieldErrors.value = parsedErrors.fieldErrors
    generalErrors.value = parsedErrors.generalErrors
  },
  { immediate: true, deep: true }
)
</script>
