import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteLocation, RouteRecordRaw, Router } from "vue-router"
import { AppPortal } from "@/models/models"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"
import AssetDetailView from "./AssetDetailView.vue"
import AssetEditView from "./AssetEditView.vue"
import AssetMAndVView from "./AssetMAndVView.vue"
import AssetView from "./AssetView.vue"
import RegisterAssetView from "./RegisterAssetView.vue"
import RegisterAssetConfirmationView from "./RegisterAssetConfirmationView.vue"
import AssetLayout from "@/modules/asset/AssetLayout.vue"
import AssetGroupView from "@/modules/asset/AssetGroupView.vue"
import AssetsView from "./AssetsView.vue"
import UploadAssetTimeSeriesView from "./UploadAssetTimeSeriesView.vue"
import UploadAssetsView from "./UploadAssetsView.vue"
import ReviewAssetTimeSeriesUploadView from "./ReviewAssetTimeSeriesUploadView.vue"
import ReviewAssetUploadView from "./ReviewAssetUploadView.vue"
import AssetDataView from "./components/AssetDataView.vue"
import AssetGroupsView from "./AssetGroupsView.vue"

export const handleAssetRowClick = (asset: { id: number; kind: string }, event: MouseEvent | KeyboardEvent, router: Router) => {
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    const subPath = asset.kind === "consumption" ? "tracking" : "eac-production"
    const path = `/assets/${asset.id}/${subPath}`
    window.open(path, "_blank")
  } else {
    routeToAssetPage(asset, router)
  }
}

export const routeToAssetPage = (asset: { id: number; kind: string }, router: Router) => {
  const route = asset.kind === "consumption" ? "wc-assets-asset-tracking" : "wc-assets-eac-production"
  router.push({ name: route, params: { assetId: asset.id } })
}

const assetIdProp = (route: RouteLocation) => ({ assetId: parseInt(scalarFromVectorOrScalar(route.params.assetId)) })
const assetGroupIdProp = (route: RouteLocation) => ({ assetGroupId: parseInt(scalarFromVectorOrScalar(route.params.assetGroupId)) })

export const assetRoutes: Array<RouteRecordRaw> = [
  {
    path: "/assets",
    name: "wc-assets",
    component: AssetsView,
    meta: {
      layout: AssetLayout,
      appPortal: AppPortal.Dashboard,
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/attestation",
    name: "wc-attestation",
    component: RegisterAssetView,
    meta: {
      layout: AssetLayout,
      appPortal: AppPortal.Dashboard,
      title: "WEATS Registration Attestation",
      breadcrumb: { name: "Dashboard", route: "wc-dashboard" },
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/attestation/confirmation",
    name: "wc-attestation-confirmation",
    component: RegisterAssetConfirmationView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Attestation submitted" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/upload",
    name: "wc-assets-upload",
    component: UploadAssetsView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Upload asset metadata to WEATS" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/review-upload",
    name: "wc-assets-review-upload",
    component: ReviewAssetUploadView,
    meta: {
      layout: AssetLayout,
      appPortal: AppPortal.Dashboard,
      title: "Review asset upload",
      breadcrumb: { name: "Upload Assets", route: "wc-assets-upload" },
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId",
    name: "wc-assets-asset",
    component: AssetView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Asset Details" },
    redirect: { name: "wc-assets-eac-production" },
    beforeEnter: authorizedRoute,
    children: [
      {
        name: "wc-assets-eac-production",
        path: "eac-production",
        component: AssetDataView,
      },
      {
        name: "wc-assets-asset-tracking",
        path: "tracking",
        component: AssetDataView,
      },
      {
        name: "wc-assets-m-and-v",
        path: "measurement-and-verification",
        component: AssetMAndVView,
      },
      {
        name: "wc-assets-asset-detail",
        path: "detail",
        component: AssetDetailView,
      },
      {
        name: "wc-assets-asset-edit",
        path: "edit",
        component: AssetEditView,
      },
    ],
  },
  {
    path: "/assets/:assetId/upload-timeseries",
    name: "wc-assets-asset-timeseries-upload",
    component: UploadAssetTimeSeriesView,
    props: assetIdProp,
    meta: {
      layout: AssetLayout,
      appPortal: AppPortal.Dashboard,
      title: "Upload asset time series data",
      breadcrumb: { name: "Asset", route: "wc-assets-asset", params: (route: RouteLocation) => ({ assetId: route.params.assetId }) },
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId/review-timeseries-upload",
    name: "wc-assets-asset-review-timeseries-upload",
    component: ReviewAssetTimeSeriesUploadView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Upload asset time series data" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/asset-groups",
    name: "wc-asset-groups",
    component: AssetGroupsView,
    meta: {
      layout: AssetLayout,
      appPortal: AppPortal.Dashboard,
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/asset-groups/:assetGroupId",
    name: "wc-asset-groups-asset-group",
    component: AssetGroupView,
    props: assetGroupIdProp,
    meta: {
      layout: AssetLayout,
      appPortal: AppPortal.Dashboard,
      title: "Asset Group Details",
      breadcrumb: { name: "Dashboard", route: "wc-dashboard" },
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/devices/register",
    name: "wc-register-device",
    redirect: { name: "wc-attestation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/devices/register/confirmation",
    name: "wc-register-device-confirmation",
    redirect: { name: "wc-attestation-confirmation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/register",
    name: "wc-register-asset",
    redirect: { name: "wc-attestation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/register/confirmation",
    name: "wc-register-asset-confirmation",
    redirect: { name: "wc-attestation-confirmation" },
    beforeEnter: authorizedRoute,
  },
]
