<template>
  <div :class="`form-field form-field-${field.type}`">
    <div v-if="!isCheckboxField" class="mb-1 flex items-center">
      <label :for="id" class="text-body-3 text-black"> {{ field.title }}{{ field.required ? "*" : "" }} </label>
      <ToolTip v-if="field.description" class="ml-2" button-class="w-3">
        <WcMarkdown :source="field.description" />
      </ToolTip>
    </div>

    <!-- Render the appropriate input based on field type -->
    <WcDropdown
      v-if="field.type === 'select'"
      :id="id"
      v-model="modelValue"
      :name="name"
      :required="field.required"
      :options="field.options ?? []"
      size="medium" />

    <div v-else-if="isCheckboxField" class="flex items-center">
      <input :id="id" v-model="modelValue" type="checkbox" :name="name" />
      <label :for="id" class="text-body-3 ml-2 text-black"> {{ field.title }}{{ field.required ? " *" : "" }} </label>
      <ToolTip v-if="field.description" class="ml-2" button-class="w-3">
        <WcMarkdown :source="field.description" />
      </ToolTip>
    </div>

    <WcInputDate v-else-if="field.type === 'date'" :id="id" v-model="modelValue" :name="name" :required="field.required" size="medium" />

    <WcInputText
      v-else-if="isTextInputField"
      :id="id"
      v-model="modelValue"
      :inputmode="field.inputmode"
      :min="field.min"
      :max="field.max"
      :name="name"
      :pattern="field.pattern"
      :required="field.required"
      :type="getInputType"
      :inset-label="field.placeholder"
      :use-grouping="false"
      size="medium" />

    <!-- Default input fields -->
    <input
      v-else
      :id="id"
      v-model="modelValue"
      :type="field.type"
      :name="name"
      :min="field.min"
      :max="field.max"
      :minlength="field.minLength"
      :maxlength="field.maxLength"
      :inputmode="field.inputmode"
      :pattern="field.pattern"
      :placeholder="field.placeholder"
      :required="field.required" />

    <div v-if="error" class="text-caption mt-1 text-error">{{ error }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import WcInputText from "@/components/input/WcInputText.vue"
import { WcDropdown } from "@/components/input"
import WcInputDate from "@/components/input/WcInputDate.vue"
import type { SelectOption } from "@/components/input"
import ToolTip from "@/components/ui/ToolTip.vue"
import WcMarkdown from "@/components/ui/WcMarkdown.vue"

const props = defineProps<{
  field: {
    title: string
    type: string
    name: string
    required?: boolean
    options?: SelectOption<string>[]
    placeholder?: string
    min?: number
    max?: number
    minLength?: number
    maxLength?: number
    inputmode?: "text" | "email" | "search" | "tel" | "url" | "none" | "numeric" | "decimal"
    pattern?: string
    multiple?: boolean
    description?: string
  }
  error?: string
  id?: string
  modelValue?: any
  name: string
  prefix?: string
}>()

const emit = defineEmits(["update:modelValue"])

// Two-way binding for modelValue to map to/from select options
const modelValue = computed({
  get: () => {
    if (props.field.type === "select") {
      const options = props.field.options || []
      return options.find((opt) => opt.value === props.modelValue) || null
    }
    return props.modelValue
  },
  set: (value) => {
    if (props.field.type === "select") {
      const options = props.field.options || []
      if (value && "value" in value) {
        emit("update:modelValue", value.value)
      } else {
        emit("update:modelValue", options.find((opt) => opt.value === value)?.value || null)
      }
    } else {
      emit("update:modelValue", value)
    }
  },
})

const isCheckboxField = computed(() => {
  return props.field.type === "checkbox"
})

const isTextInputField = computed(() => {
  return ["text", "email"].includes(props.field.type)
})

const getInputType = computed(() => {
  return props.field.type === "email" ? "email" : undefined
})
</script>

<style lang="scss" scoped>
.form-field-checkbox label {
  @apply flex-row gap-2;

  input {
    @apply mt-1;
  }
}
</style>
