import type { ContactInfo } from "@/client/types.gen"

// Formats a contact info object into a human-readable address string
export const formatAddress = (contactInfo: ContactInfo): string => {
  if (!contactInfo) {
    return "—"
  }

  const street1 = contactInfo.street_1 || ""
  const street2 = contactInfo.street_2 || ""
  const city = contactInfo.city || ""
  const stateProvince = contactInfo.state_province || ""
  const postalCode = contactInfo.postal_code || ""

  const streetAddress = [street1, street2].filter(Boolean).join(", ")
  const cityStateZip = [city, stateProvince, postalCode].filter(Boolean).join(", ")

  const addressParts = [streetAddress, cityStateZip].filter(Boolean)

  return addressParts.length > 0 ? addressParts.join(", ") : "—"
}
