<template>
  <WcModal :is-open="true" header="Register asset" size="full" :icon="null" @update:is-open="(value) => !value && handleClose()">
    <div class="flex h-full flex-col text-black md:flex-row">
      <WcWizardSteps class="md:fixed md:h-[calc(100vh-65px)]">
        <WcWizardStep
          v-for="step in visibleSteps.filter((step) => step !== Step.MODEL_ENHANCEMENT && step !== Step.ASSET_REGISTRATION)"
          :key="step"
          :title="getStepTitle(step)"
          :is-active="currentStep === step"
          :is-completed="isStepCompleted(step)" />
        <WcWizardStep
          title="Data inputs"
          :is-active="currentStep === Step.ASSET_REGISTRATION || currentStep === Step.MODEL_ENHANCEMENT"
          :is-completed="currentStep === Step.MODEL_ENHANCEMENT" />
        <WcWizardNestedStep
          title="Asset registration"
          :is-active="currentStep === Step.ASSET_REGISTRATION"
          :is-completed="currentStep === Step.MODEL_ENHANCEMENT" />
        <WcWizardNestedStep title="Model enhancement" :is-active="currentStep === Step.MODEL_ENHANCEMENT" :is-completed="false" />
      </WcWizardSteps>

      <div class="flex flex-1 flex-col bg-neutral-10 md:ml-[335px]">
        <div class="flex-1 overflow-y-auto px-4 py-8 md:px-[60px]">
          <RegistrationTypeStep v-if="currentStep === Step.REGISTRATION_TYPE" v-model="selectedRegistrationType" />

          <SelectMetersStep
            v-else-if="currentStep === Step.SELECT_METERS"
            v-model="selectedMeters"
            :selected-registration-type="selectedRegistrationType" />

          <ChooseMethodologyStep v-else-if="currentStep === Step.CHOOSE_METHODOLOGY" v-model="selectedMethodology" />

          <AssetRegistrationStep
            v-else-if="currentStep === Step.ASSET_REGISTRATION"
            ref="assetRegistrationStepRef"
            v-model="assetData"
            :selected-methodology="selectedMethodology"
            :selected-meters="selectedMeters"
            :error="formError"
            @submit="goToNextStep" />

          <ModelEnhancementStep
            v-else-if="currentStep === Step.MODEL_ENHANCEMENT"
            v-model="assetData"
            :selected-methodology="selectedMethodology"
            :selected-meters="selectedMeters"
            :error="formError"
            @submit="createAsset" />
        </div>

        <div class="sticky bottom-0 flex justify-end bg-neutral-10 px-4 py-3 shadow-[0_-2px_2px_-1px_rgba(0,0,0,0.085)] md:px-[60px] md:py-6">
          <div class="flex items-center gap-4">
            <WcButton v-if="currentStep !== Step.REGISTRATION_TYPE" text="Back" variant="secondary" size="medium" @click="goToPreviousStep" />
            <WcButton v-if="!isTerminalStep" text="Next" variant="primary" size="medium" :is-disabled="!canProceedToNextStep" @click="goToNextStep" />
            <WcButton
              v-else
              text="Submit"
              :icon="isCreatingAsset ? 'spinner' : undefined"
              variant="primary"
              size="medium"
              :is-disabled="!canProceedToNextStep || isCreatingAsset"
              @click="submitForm" />
          </div>
        </div>
      </div>
    </div>

    <template #actions>
      <WcButton text="Cancel" variant="secondary" size="medium" dark-mode @click="handleClose" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { useToast } from "vue-toastification"
import { devicesCreateDevice } from "@/client/sdk.gen"
import type { Meter } from "@/client/types.gen"
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import { WcWizardSteps, WcWizardStep, WcWizardNestedStep } from "@/components/wizard"
import { RegistrationType } from "@/models/asset"
import type { Methodology } from "@/modules/asset/asset.service"
import { ApiDataProvider, partnerForDataProvider } from "@/modules/dataProvider/dataProvider.service"
import { methodologies } from "./steps/methodologies"
import { useOpenApiStore } from "@/components/form/openapi.state"
import AssetRegistrationStep from "./steps/AssetRegistrationStep.vue"
import ChooseMethodologyStep from "./steps/ChooseMethodologyStep.vue"
import ModelEnhancementStep from "./steps/ModelEnhancementStep.vue"
import RegistrationTypeStep from "./steps/RegistrationTypeStep.vue"
import SelectMetersStep from "./steps/SelectMetersStep.vue"

// this is only loaded to get the spec fetching done in the background so the
// later steps don't have any load time
const _openApiStore = useOpenApiStore()

enum Step {
  REGISTRATION_TYPE = "registration-type",
  SELECT_METERS = "select-meters",
  CHOOSE_METHODOLOGY = "choose-methodology",
  ASSET_REGISTRATION = "asset-registration",
  MODEL_ENHANCEMENT = "model-enhancement",
}

const stepFlow: Record<Step, Step | null> = {
  [Step.REGISTRATION_TYPE]: Step.SELECT_METERS,
  [Step.SELECT_METERS]: Step.ASSET_REGISTRATION,
  [Step.CHOOSE_METHODOLOGY]: Step.SELECT_METERS,
  [Step.ASSET_REGISTRATION]: Step.MODEL_ENHANCEMENT,
  [Step.MODEL_ENHANCEMENT]: null,
}

const reverseStepFlow: Record<Step, Step | null> = {
  [Step.REGISTRATION_TYPE]: null,
  [Step.SELECT_METERS]: Step.REGISTRATION_TYPE,
  [Step.CHOOSE_METHODOLOGY]: Step.REGISTRATION_TYPE,
  [Step.ASSET_REGISTRATION]: Step.SELECT_METERS,
  [Step.MODEL_ENHANCEMENT]: Step.ASSET_REGISTRATION,
}

const toast = useToast()

const emit = defineEmits<{
  (e: "modal-reject"): void
  (e: "modal-resolve", value: { assetId: number; kind: string }): void
}>()

const currentStep = ref<Step>(Step.REGISTRATION_TYPE)
const selectedRegistrationType = ref<RegistrationType>(RegistrationType.TRACKING)
const selectedMethodology = ref<Methodology | null>(null)
const assetData = ref<Partial<Record<string, any>>>({})
const formError = ref<any>(null)
const isCreatingAsset = ref<boolean>(false)
const selectedMeters = ref<Meter[]>([])
const assetRegistrationStepRef = ref<{ form: HTMLFormElement | null } | null>(null)

const isStepCompleted = (step: Step): boolean => {
  const currentStepIndex = visibleSteps.value.indexOf(currentStep.value)
  const stepIndex = visibleSteps.value.indexOf(step)

  // Mark Data inputs as completed when we're on model enhancement
  if (step === Step.ASSET_REGISTRATION && currentStep.value === Step.MODEL_ENHANCEMENT) {
    return true
  }

  return stepIndex < currentStepIndex
}

const handleClose = () => {
  resetModal()
  emit("modal-reject")
}

const validateAssetRegistrationStep = (): boolean => {
  const hasValidLocation =
    assetData.value.location?.street && assetData.value.location?.city && assetData.value.location?.state && assetData.value.location?.postalCode
  if (!hasValidLocation) {
    formError.value = { detail: [{ msg: "Please enter a valid location" }] }
    return false
  }

  const form = assetRegistrationStepRef.value?.form
  if (form && !form.checkValidity()) {
    form.reportValidity()
    return false
  }

  formError.value = null
  return true
}

const canProceedToNextStep = computed(() => {
  if (currentStep.value === Step.REGISTRATION_TYPE) {
    return selectedRegistrationType.value !== null
  }

  if (currentStep.value === Step.SELECT_METERS) {
    if (selectedRegistrationType.value === RegistrationType.TRACKING) {
      return selectedMeters.value.length > 0
    }
    return true
  }

  if (currentStep.value === Step.CHOOSE_METHODOLOGY) {
    return !!selectedMethodology.value
  }

  return true
})

const goToNextStep = () => {
  formError.value = null

  if (currentStep.value === Step.ASSET_REGISTRATION && !validateAssetRegistrationStep()) {
    return
  }

  const nextStep = stepFlow[currentStep.value]
  if (nextStep) {
    currentStep.value = nextStep
  }
}

const goToPreviousStep = () => {
  const previousStep = reverseStepFlow[currentStep.value]
  if (previousStep) {
    currentStep.value = previousStep
  }
}

const resetModal = () => {
  currentStep.value = Step.REGISTRATION_TYPE
  selectedRegistrationType.value = RegistrationType.TRACKING
  selectedMeters.value = []
  selectedMethodology.value = null
  assetData.value = {}
  formError.value = null
  isCreatingAsset.value = false
}

const generateDefaultAssetName = (): string => {
  const methodologyName = selectedRegistrationType.value === RegistrationType.EAC ? selectedMethodology.value?.name || "" : "Tracking"
  if (selectedMeters.value.length === 0) {
    return methodologyName ? `${methodologyName} asset` : "M&V asset"
  }

  const firstMeter = selectedMeters.value[0]
  const partner = partnerForDataProvider(firstMeter.apiProvider as ApiDataProvider)
  if (selectedMeters.value.length > 1) {
    return `${methodologyName} asset for ${partner?.name || ""} meters ${selectedMeters.value.map((meter) => meter.id).join(", ")}`
  }
  return `${methodologyName} asset for ${partner?.name || ""} meter ${firstMeter.id}`
}

const createAsset = async (formData: Record<string, any>): Promise<void> => {
  try {
    isCreatingAsset.value = true

    const assetToCreate = {
      ...assetData.value,
      ...formData,
      location: assetData.value.location ?? formData.location,
      kind: selectedMethodology.value?.kind,
      meterIds: selectedMeters.value.map((meter) => meter.id),
    }

    const result = await devicesCreateDevice({
      body: assetToCreate as any,
    })

    toast.success("Asset created")
    emit("modal-resolve", { assetId: result.data.id, kind: result.data.kind })
  } catch (error: any) {
    console.error(error)
    formError.value = error
  } finally {
    isCreatingAsset.value = false
  }
}

const submitForm = () => {
  const form = document.querySelector("form")
  if (form) {
    const submitEvent = new Event("submit", {
      bubbles: true,
      cancelable: true,
    })
    form.dispatchEvent(submitEvent)
  }
}

const visibleSteps = computed(() => {
  if (selectedRegistrationType.value === RegistrationType.TRACKING) {
    return [Step.REGISTRATION_TYPE, Step.SELECT_METERS, Step.ASSET_REGISTRATION, Step.MODEL_ENHANCEMENT]
  }

  return [Step.REGISTRATION_TYPE, Step.CHOOSE_METHODOLOGY, Step.SELECT_METERS, Step.ASSET_REGISTRATION, Step.MODEL_ENHANCEMENT]
})

const getStepTitle = (step: Step): string => {
  switch (step) {
    case Step.REGISTRATION_TYPE:
      return "Registration type"
    case Step.SELECT_METERS:
      return "Select meters"
    case Step.CHOOSE_METHODOLOGY:
      return "Choose methodology"
    case Step.ASSET_REGISTRATION:
      return "Data inputs"
    case Step.MODEL_ENHANCEMENT:
      return "Model enhancement"
    default:
      return ""
  }
}

const isTerminalStep = computed(() => stepFlow[currentStep.value] === null)

// Initialize assetData when methodology or meters change
watch(
  [() => selectedMethodology.value, () => selectedMeters.value],
  ([methodology, meters]) => {
    if (methodology) {
      assetData.value.name = generateDefaultAssetName()
      assetData.value.kind = methodology.kind
    }

    if (meters.length > 0) {
      const firstMeter = meters[0]

      assetData.value.location = {
        street: firstMeter.street,
        city: firstMeter.city,
        state: firstMeter.state,
        postalCode: firstMeter.zipCode,
      }
    }
  },
  { immediate: true, deep: true }
)

// Watch for registration type changes to update step flow
watch(
  selectedRegistrationType,
  (type) => {
    if (type === RegistrationType.EAC) {
      stepFlow[Step.REGISTRATION_TYPE] = Step.CHOOSE_METHODOLOGY
      stepFlow[Step.CHOOSE_METHODOLOGY] = Step.SELECT_METERS
      stepFlow[Step.SELECT_METERS] = Step.ASSET_REGISTRATION
      stepFlow[Step.ASSET_REGISTRATION] = Step.MODEL_ENHANCEMENT
      stepFlow[Step.MODEL_ENHANCEMENT] = null

      reverseStepFlow[Step.REGISTRATION_TYPE] = null
      reverseStepFlow[Step.CHOOSE_METHODOLOGY] = Step.REGISTRATION_TYPE
      reverseStepFlow[Step.SELECT_METERS] = Step.CHOOSE_METHODOLOGY
      reverseStepFlow[Step.ASSET_REGISTRATION] = Step.SELECT_METERS
      reverseStepFlow[Step.MODEL_ENHANCEMENT] = Step.ASSET_REGISTRATION

      selectedMethodology.value = methodologies[0]
    } else {
      stepFlow[Step.REGISTRATION_TYPE] = Step.SELECT_METERS
      stepFlow[Step.SELECT_METERS] = Step.ASSET_REGISTRATION
      stepFlow[Step.ASSET_REGISTRATION] = Step.MODEL_ENHANCEMENT
      stepFlow[Step.MODEL_ENHANCEMENT] = null

      reverseStepFlow[Step.REGISTRATION_TYPE] = null
      reverseStepFlow[Step.SELECT_METERS] = Step.REGISTRATION_TYPE
      reverseStepFlow[Step.ASSET_REGISTRATION] = Step.SELECT_METERS
      reverseStepFlow[Step.MODEL_ENHANCEMENT] = Step.ASSET_REGISTRATION

      // Set methodology to consumption for tracking type
      selectedMethodology.value = { kind: "consumption", name: "Consumption", url: null }
    }
  },
  { immediate: true }
)
</script>
