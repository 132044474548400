<template>
  <AppPageSection>
    <InlineError v-if="hasError" error-message="There was a problem loading M&V info about this asset. Please try again." />

    <InlineLoading v-else-if="isLoading" />
    <EmptyState v-else-if="methodologyDescription === null" title="M&V description pending successful audit" />
    <MAndVSummary v-else :methodology-description="methodologyDescription" />
  </AppPageSection>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import type { Asset } from "@/models/asset"
import { AppPageSection } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import { MethodologyDescription } from "@/models/audit"
import { auditsGetLatestDeviceAuditTrail } from "@/client/sdk.gen"
import MAndVSummary from "@/modules/eacs/components/MAndVSummary.vue"

const props = defineProps<{ asset: Asset }>()

const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const methodologyDescription = ref<MethodologyDescription | null>(null)

watch(
  () => props.asset,
  async (asset) => {
    if (!asset) return
    isLoading.value = true
    try {
      // Show M&V info for the asset's latest audit trail
      const result = await auditsGetLatestDeviceAuditTrail({ query: { deviceId: asset.id } })
      methodologyDescription.value = result.data?.methodologyDescription ?? null
    } catch (error) {
      hasError.value = true
      console.error("There was an error loading this asset", error)
    } finally {
      isLoading.value = false
    }
  },
  { immediate: true }
)
</script>
