<template>
  <AppPage class="w-full">
    <section class="bg-background-sagelight">
      <AppPageHeader
        :backlink-route="
          asset?.group?.id ? { name: 'wc-asset-groups-asset-group', params: { assetGroupId: asset.group.id } } : { name: 'wc-dashboard' }
        "
        :backlink-text="asset?.group?.name ?? 'Dashboard'"
        class="-mb-9">
        <span class="text-subheading-large-bold"> {{ asset ? getAssetDisplayName(asset) : "" }}</span>
        <div class="mb-8 mt-3 flex flex-col items-start">
          <div v-if="asset" class="flex rounded bg-neutral-10 px-4 py-2">
            <AssetStatusIcon :status="asset.status" class="mt-0.5" />
            <div class="text-subheading-2">
              {{ ASSET_STATUS[asset.status]?.summary }}
            </div>
          </div>
        </div>
        <div class="col-span-1 min-h-16">
          <template v-if="!isTrackingAsset">
            <div class="mb-4 flex items-center">
              <h3 class="text-overline-2 inline-block">Total EACs</h3>
            </div>
            <div>
              <div class="mb-1">
                <span class="text-featured-number pr-2" data-cy="total-retired"> {{ netEacs.quantity }} </span>
                <span class="text-body-2"> {{ netEacs.unit }} </span>
              </div>
              <div v-if="Number(netElectricityEacs.quantity) > 0 || Number(netCarbonEacs.quantity) > 0" class="text-body-3">
                {{ netElectricityEacs.quantity }} {{ netElectricityEacs.unit }} + {{ netCarbonEacs.quantity }} {{ netCarbonEacs.unit }}
              </div>
            </div>
          </template>
        </div>
        <template #title-adjacent>
          <div v-if="isAristotleEnabled && asset" class="basis-[26rem]">
            <AssetJobStatuses ref="assetJobStatusesRef" :asset-id="asset.id" />
          </div>
        </template>
      </AppPageHeader>
      <WcTabs>
        <WcTabRouterLink v-if="isTrackingAsset" :to="{ name: 'wc-assets-asset-tracking' }"> Tracking </WcTabRouterLink>
        <WcTabRouterLink v-if="!isTrackingAsset" :to="{ name: 'wc-assets-eac-production' }"> EAC Production </WcTabRouterLink>
        <WcTabRouterLink v-if="!isTrackingAsset" :to="{ name: 'wc-assets-m-and-v' }">M&V</WcTabRouterLink>
        <WcTabRouterLink :to="{ name: 'wc-assets-asset-detail' }">Details</WcTabRouterLink>
        <WcTabRouterLink v-if="isAristotleEnabled === true" :to="{ name: 'wc-assets-asset-edit' }">Edit</WcTabRouterLink>
      </WcTabs>
    </section>
    <section class="wc-page-container mt-9 w-full">
      <InlineLoading v-if="isLoading" />
      <InlineError v-else-if="hasError" error-message="There was a problem loading this asset. Please try again." />
      <router-view v-else :asset="asset" :asset-id="assetId" :summary="assetSummary" @asset-updated="handleAssetUpdated"></router-view>
    </section>
  </AppPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import type { Asset } from "@/models/asset"
import { ASSET_STATUS, getAssetDisplayName } from "@/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import { AppPage, AppPageHeader, WcTabRouterLink, WcTabs } from "@/components/layout"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import type { AssetSummary } from "@/modules/asset/asset.service"
import { netWhFromValues, netGco2eFromValues, netEacsFromValues } from "@/modules/registry/registry.utils"
import { useAssetService } from "@/services/service-container"
import AssetJobStatuses from "./components/AssetJobStatuses.vue"
import AssetStatusIcon from "./components/AssetStatusIcon.vue"
import { useAristotle } from "@/features"

const assetService = useAssetService()
const isAristotleEnabled = useAristotle()

const props = defineProps<{ assetId: number }>()

const asset = ref<Asset | undefined>()

const assetSummary = ref<AssetSummary | undefined>()
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const assetJobStatusesRef = ref<{ restartPolling: () => void } | null>(null)

const isTrackingAsset = computed(() => asset.value?.kind === "consumption")

const handleAssetUpdated = async (updatedAsset: Asset) => {
  asset.value = updatedAsset
  try {
    assetSummary.value = await assetService.getAssetSummary(props.assetId)
    assetJobStatusesRef.value?.restartPolling()
  } catch (error) {
    console.error("Error refreshing asset summary:", error)
  }
}

onMounted(async () => {
  try {
    asset.value = await assetService.getAsset(props.assetId)
    assetSummary.value = await assetService.getAssetSummary(props.assetId)
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset", error)
  }
  isLoading.value = false
})

const eacSummaryEntries = computed(() => {
  if (!assetSummary.value?.eacs.sum && !assetSummary.value?.provisionalEacs.sum) {
    return []
  }
  return assetSummary.value.eacs.sum
})

const netEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "0", unit: "" }
  const netEacs = netEacsFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netEacs, EacMeasurementParameter.UnlabeledLong)
})

const netElectricityEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "", unit: "" }
  const netElectricity = netWhFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netElectricity, EacMeasurementParameter.Electricity)
})

const netCarbonEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "", unit: "" }
  const netCarbon = netGco2eFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netCarbon, EacMeasurementParameter.GhgEmissions)
})
</script>
