<template>
  <div>
    <h2 class="text-subheading-large-bold mb-4">Edit Asset</h2>
    <p class="text-body-2 mb-3">Update your asset's details</p>
    <div class="text-caption mb-12 text-hint">*indicates required field</div>

    <InlineLoading v-if="isLoading" />

    <div v-else-if="!componentPointer || !formSchema" class="text-error">Unable to load asset form. Please try again.</div>

    <div v-else>
      <form ref="assetFormRef" class="flex flex-col space-y-6" @submit.prevent="handleSubmit">
        <!-- Project Name and Internal ID fields -->
        <div v-if="formSchema?.fields" class="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2">
          <div v-for="fieldName in ['name', 'customId']" :key="fieldName">
            <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, fieldName)" :errors="fieldErrors" />
          </div>
        </div>

        <h3 class="text-subheading-2 mb-8">Required fields</h3>

        <!-- Special Location field -->
        <div class="grid grid-cols-1 items-start gap-6 lg:grid-cols-2">
          <div>
            <label class="text-body-3 pl-0 text-black" for="asset-location"> Location * </label>
            <WcAutocompleteAddress
              id="asset-location"
              :address="formattedLocationAddress"
              data-cy="input-asset-location"
              input-class="!py-2"
              @set-address="setLocationFromAddress" />
          </div>
        </div>

        <!-- Special fields for Electrification methodology assets -->
        <ElectrificationRequiredFields
          v-if="formSchema?.fields && props.asset.category === AssetCategory.electrification"
          v-model="formValue"
          :form-schema="formSchema"
          :field-errors="fieldErrors" />

        <!-- Required fields for all other methodology assets -->
        <div v-else-if="formSchema?.fields" class="grid grid-cols-1 items-start gap-6 lg:grid-cols-2 xl:grid-cols-3">
          <WcOpenApiFormFields v-model="formValue" :form="requiredFormSchema" :hide="hiddenRequiredFormFields" :errors="fieldErrors" />
        </div>

        <h3 class="text-subheading-2 !mt-12">Model enhancement</h3>
        <p class="text-body-2 !mt-4 mb-12">Help Aristotle build a better version of your reference model by providing more details</p>

        <!-- Optional fields for all methodology assets -->
        <div v-if="formSchema?.fields" class="grid grid-cols-1 items-start gap-6 lg:grid-cols-2 xl:grid-cols-3">
          <WcOpenApiFormFields v-model="formValue" :form="optionalFormSchema" :hide="hiddenOptionalFormFields" :errors="fieldErrors" />
        </div>

        <h3 class="text-subheading-2 !mt-12">Meters</h3>
        <p class="text-body-2 !mt-4 mb-12">Configure which meters are associated with this asset</p>

        <!-- Selected meters display -->
        <div v-if="selectedMeters.length > 0" class="mb-6 flex flex-col gap-2">
          <div
            v-for="meter in selectedMeters"
            :key="meter.id"
            class="flex max-w-[944px] flex-col rounded-sm border border-sagetone-light bg-white p-4">
            <div class="flex justify-between gap-2">
              <div class="flex flex-col gap-2">
                <div class="flex items-center gap-3">
                  <h3 class="text-subheading-2">Meter {{ meter.id }}</h3>
                  <span
                    class="text-body-3 inline-flex items-center rounded-full border px-3 capitalize"
                    :class="{
                      'border-cornyellow-200 bg-cornyellow-200': meter.meterType === 'electricity',
                      'border-info-light bg-info-light': meter.meterType !== 'electricity',
                    }"
                    >{{ meter.meterType }}</span
                  >
                </div>
                <p class="text-body-2">{{ meter.address }}</p>
              </div>
              <div class="self-center">
                <WcIconButton icon="wc-carbon:trash-can" size="small" @click.stop="removeMeter(meter.id)" />
              </div>
            </div>
          </div>
        </div>

        <!-- Select meters button -->
        <div class="mb-12 flex items-center justify-between">
          <WcButton
            :text="selectedMeters.length > 0 ? 'Add more meters' : 'Select meters'"
            color="sage"
            variant="primary"
            size="medium"
            @click="handleSelectMeters" />
        </div>

        <div v-if="generalErrors.length > 0" class="mb-4 rounded-md border border-error bg-error-light p-4">
          <h5 class="text-subheading-2 mb-2 text-error">Form Validation Errors</h5>
          <ul class="list-disc space-y-1 pl-5">
            <li v-for="generalError in generalErrors" :key="generalError" class="text-error">{{ generalError }}</li>
          </ul>
        </div>

        <div class="flex justify-end">
          <WcButton
            type="submit"
            text="Save asset"
            :icon="isSaving ? 'spinner' : undefined"
            :is-disabled="isSaving"
            variant="primary"
            size="medium" />
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { TYPE, useToast } from "vue-toastification"
import { Asset, AssetCategory } from "@/models/asset"
import { useOpenApiStore } from "@/components/form/openapi.state"
import { ApiValidationError, extractSingleField, prepareForm, parseApiErrors } from "@/components/form/WcOpenApiForm.utils"
import WcOpenApiFormFields from "@/components/form/WcOpenApiFormFields.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import WcAutocompleteAddress from "@/components/WcAutocompleteAddress.vue"
import { useWarnForUnsavedChanges } from "@/composables/useWarnForUnsavedChanges"
import ElectrificationRequiredFields from "@/modules/asset/components/fields/ElectrificationRequiredFields.vue"
import { formatAddress } from "@/utils/formatAddress"
import { useAssetService } from "@/services/service-container"
import { WcButton, WcIconButton } from "@/components/button"
import { useModals } from "vue-promise-modals"
import SelectMetersModal from "@/modules/meter/components/SelectMetersModal.vue"
import type { Meter } from "@/client/types.gen"
import { metersListMetersPaginated } from "@/client/sdk.gen"

const BASE_HIDDEN_FORM_FIELDS = ["customId", "kind", "groupId", "groupName", "location", "meterIds", "name"]

type AssetData = Record<string, any>

const props = defineProps<{
  asset: Asset
}>()

const emit = defineEmits<{
  (e: "asset-updated", asset: Asset): void
}>()

const toast = useToast()
const assetService = useAssetService()
const openApiStore = useOpenApiStore()
const { openModal } = useModals()

const assetFormRef = ref<HTMLFormElement | null>(null)

const isLoading = ref<boolean>(true)
const isSaving = ref<boolean>(false)
const fieldErrors = ref<ApiValidationError[]>([])
const generalErrors = ref<string[]>([])

const formValue = ref<AssetData>({})
const selectedMeters = ref<Meter[]>([])

const initialFormValue = ref<AssetData>({})
const initialSelectedMeters = ref<Meter[]>([])

// Combine these for tracking unsaved changes
const combinedFormState = computed(() => ({
  formValue: formValue.value,
  selectedMeters: selectedMeters.value,
}))

const initialFormState = computed(() => ({
  formValue: initialFormValue.value,
  selectedMeters: initialSelectedMeters.value,
}))

const { resetUnsavedChanges } = useWarnForUnsavedChanges(initialFormState, combinedFormState)

// Watch for asset changes to update form value and selected meters
watch(
  () => props.asset,
  async (newAsset) => {
    if (newAsset) {
      formValue.value = { ...newAsset }
      try {
        const result = await metersListMetersPaginated({
          query: {
            device_id: newAsset.id,
            per_page: 100,
          },
        })
        selectedMeters.value = result.data.sort((a, b) => a.id - b.id)

        initialFormValue.value = JSON.parse(JSON.stringify({ ...formValue.value }))
        initialSelectedMeters.value = JSON.parse(JSON.stringify([...selectedMeters.value]))
        resetUnsavedChanges()
      } catch (error) {
        console.error("Error loading meters", error)
      }
    }
  },
  { immediate: true, deep: true }
)

const hiddenRequiredFormFields = computed(() => {
  const fields = [...BASE_HIDDEN_FORM_FIELDS]
  if (props.asset.category === AssetCategory.electrification) {
    fields.push("replacesDryer", "replacesFurnace", "replacesOven", "replacesPoolHeater", "replacesWaterHeater")
  }
  return fields
})

const hiddenOptionalFormFields = computed(() => {
  const fields = [...BASE_HIDDEN_FORM_FIELDS]
  if (props.asset.category === AssetCategory.electrification) {
    fields.push(
      "replacesDryer",
      "replacesFurnace",
      "replacesOven",
      "replacesPoolHeater",
      "replacesWaterHeater",
      "previousFuel",
      "previousFuelAnnualUsageMmbtu",
      "effectiveUsefulLifeYears",
      "energyEfficiencyImprovements",
      "heatingBackupSystem",
      "waterHeaterUef",
      "heatPumpHspf",
      "dryerCef",
      "coldClimate",
      "previousFurnaceAfue",
      "previousWaterHeaterUef",
      "previousInsulationStatus",
      "likelyHeatingThermostatSetpointF",
      "likelyHeatingThermostatSetpointOffsetF",
      "infiltrationRateAch50"
    )
  }
  return fields
})

const componentPointer = computed(() => {
  if (!props.asset) {
    return null
  }

  const discriminator = openApiStore.dereferenceUri("#/paths/~1devices/post/requestBody/content/application~1json/schema/discriminator")
  if (!discriminator) {
    return null
  }
  return discriminator.mapping[props.asset.kind]
})

const formSchema = computed(() => {
  if (!componentPointer.value) {
    return null
  }
  const baseSchema = prepareForm(openApiStore, componentPointer.value)
  if (!baseSchema?.fields) {
    return null
  }
  return baseSchema
})

// Watch for form schema changes to update loading state
watch(
  formSchema,
  (newSchema) => {
    if (newSchema) {
      isLoading.value = false
    }
  },
  { immediate: true }
)

const requiredFormSchema = computed(() => {
  if (!formSchema.value?.fields) {
    return {
      type: "form" as const,
      name: "",
      title: "",
      fields: [],
    }
  }

  return {
    ...formSchema.value,
    fields: formSchema.value.fields.filter((field) => field.required),
  }
})

const optionalFormSchema = computed(() => {
  if (!formSchema.value?.fields) {
    return {
      type: "form" as const,
      name: "",
      title: "",
      fields: [],
    }
  }

  return {
    ...formSchema.value,
    fields: formSchema.value.fields.filter((field) => !field.required),
  }
})

const formattedLocationAddress = computed(() => {
  const location = formValue.value.location
  if (!location) {
    return ""
  }
  const formattedAddress = formatAddress({
    street_1: location.street,
    city: location.city,
    state_province: location.state,
    postal_code: location.postalCode,
    country: location.country,
  })

  return formattedAddress !== "—" ? formattedAddress : ""
})

const setLocationFromAddress = (address: any) => {
  formValue.value = {
    ...formValue.value,
    location: {
      street: address.street,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country || "US",
    },
  }
}

const validateFormData = (form: HTMLFormElement): boolean => {
  if (form && !form.checkValidity()) {
    form.reportValidity()
    return false
  }

  return true
}

const handleSubmit = async () => {
  const form = assetFormRef.value
  if (!form || !validateFormData(form)) {
    return
  }

  fieldErrors.value = []
  generalErrors.value = []

  const toastId = toast("Saving asset…", { type: TYPE.DEFAULT, timeout: false })
  isSaving.value = true

  try {
    const updatedAsset = await assetService.updateAsset({
      ...formValue.value,
      meterIds: selectedMeters.value.map((meter) => meter.id),
      id: props.asset.id,
    } as Asset)

    toast.update(toastId, { content: "Asset updated", options: { type: TYPE.SUCCESS, timeout: 1000 } })
    emit("asset-updated", updatedAsset.data)

    initialFormValue.value = JSON.parse(JSON.stringify({ ...formValue.value }))
    initialSelectedMeters.value = JSON.parse(JSON.stringify([...selectedMeters.value]))
    resetUnsavedChanges()
  } catch (error: any) {
    toast.update(toastId, { content: "Error updating asset", options: { type: TYPE.ERROR } })

    if (error.response?.status === 400) {
      generalErrors.value = [error.response.data.detail]
    } else if (error.response?.status === 422) {
      const parsedErrors = parseApiErrors(error.response.data)
      fieldErrors.value = parsedErrors.fieldErrors
      generalErrors.value = parsedErrors.generalErrors
    } else {
      throw error
    }
  } finally {
    isSaving.value = false
  }
}

const removeMeter = (meterId: number) => {
  selectedMeters.value = selectedMeters.value.filter((meter) => meter.id !== meterId)
}

const handleSelectMeters = async () => {
  let result
  try {
    result = await openModal(SelectMetersModal)
    const existingMeterIds = selectedMeters.value.map((meter) => meter.id)
    const newMeters = result.meters.filter((meter) => !existingMeterIds.includes(meter.id))
    const allMeters = [...selectedMeters.value, ...newMeters].sort((a, b) => a.id - b.id)
    selectedMeters.value = allMeters
  } catch {
    return
  }
}

defineExpose({
  form: assetFormRef,
})
</script>
