<template>
  <WcTable
    :data="assetsData"
    :columns="[
      { key: 'name', label: 'Name', align: 'left', thClass: 'w-2/12' },
      ...(showUpdated ? [{ key: 'updatedDate', label: 'Last Update', align: 'left', thClass: showGroup ? 'w-1/12' : 'w-2/12' } as TableHeader] : []),
      { key: 'status', label: 'Status', align: 'left', thClass: 'w-2/12' },
      { key: 'location', label: 'Location', align: 'left', thClass: 'w-2/12' },
      ...(showAccount ? [{ key: 'accountName', label: 'Account', align: 'left', thClass: 'w-2/12' } as TableHeader] : []),
      ...(showKind ? [{ key: 'kind', label: 'Resource type', align: 'left', thClass: 'w-2/12' } as TableHeader] : []),
      ...(showGroup ? [{ key: 'group', label: 'Group', align: 'left', thClass: 'w-1/12' } as TableHeader] : []),
      ...(showEacs ? [{ key: 'eacs', label: 'Net EACs', align: 'right', thClass: 'w-1/12' } as TableHeader] : []),
      ...(showDelete ? [{ key: 'delete', label: '', align: 'right', thClass: 'w-1/12' } as TableHeader] : []),
    ]"
    row-id-field="id"
    :table-class="`text-body-3 min-w-main-content table-fixed ${tableClass}`"
    tr-class="cursor-pointer hover:bg-neutral-10 transition-colors border-b border-neutral-20"
    th-class="text-left py-2"
    td-class="p-3"
    :select-on-row-click="selectOnRowClick"
    :sortable="true">
    <template #status="{ row }: { row: AssetRow }">
      <div class="flex items-center">
        <div
          class="-my-1 rounded px-3 py-1"
          :class="{
            'bg-warning-light': row.status === AssetStatus.draft,
            'bg-info-light': row.status === AssetStatus.ready,
            'bg-neutral-10': row.status === AssetStatus.archived,
          }">
          {{ row.statusSummary }}
        </div>
      </div>
    </template>
    <template #kind="{ row }: { row: AssetRow }">
      {{ assetStore.formatAssetKindCategory(row.kind) }}
    </template>
    <template #delete="{ row }: { row: AssetRow }">
      <WcButton icon="wc-carbon:delete" size="extra-small" text="Delete" @click="(event) => emit('row-delete-clicked', row.id, event)" />
    </template>
  </WcTable>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { WcButton } from "@/components/button"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import { ASSET_STATUS, AssetStatus } from "@/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import type { AssetWithSummary } from "@/modules/asset/asset.service"
import { useAssetStore } from "@/modules/asset/asset.state"
import { netEacsFromValues } from "@/modules/registry/registry.utils"
import { BaseDeviceOutWithSummary, DeviceStatus } from "@/client"

const assetStore = useAssetStore()

type AssetRow = {
  id: number
  name: string
  accountName: string
  kind: string
  location: string
  status: AssetStatus | DeviceStatus
  statusSummary: string
  updatedDate: string
}

const props = defineProps<{
  assets: AssetWithSummary[] | BaseDeviceOutWithSummary[]
  selectOnRowClick?: boolean
  showAccount?: boolean
  showDelete?: boolean
  showEacs?: boolean
  showGroup?: boolean
  showKind?: boolean
  showUpdated?: boolean
  tableClass?: string
}>()

const getAssetNetEacs = (group: AssetWithSummary | BaseDeviceOutWithSummary) => {
  if ((group?.summary?.eacs?.sum?.length ?? 0) === 0) return ""
  const netEacs = netEacsFromValues(group.summary.eacs.sum)
  return getFormattedEacQuantity(netEacs, EacMeasurementParameter.UnlabeledShort).toString()
}

const emit = defineEmits<{ "row-delete-clicked": [rowId: number, event: MouseEvent] }>()
const assetsData = computed<AssetRow[]>(() => {
  return props.assets.map((asset) => {
    return {
      id: asset.id,
      name: asset.name || `Asset ${asset.id}`,
      accountName: asset.account?.name || "",
      kind: asset.kind,
      group: asset.group?.name || "",
      location: `${asset.city}, ${asset.state}`,
      status: asset.status,
      statusSummary: ASSET_STATUS[asset.status].summary || asset.status,
      updatedDate: new Date(asset.updatedTime).toISOString().split("T")[0],
      eacs: props.showEacs ? getAssetNetEacs(asset) : "",
    }
  })
})
</script>
