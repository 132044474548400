<template>
  <div v-if="!!asset || isLoading" class="p-3">
    <Icon v-if="assetId && isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
    <div v-if="asset && !isLoading">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-3xl break-words">{{ assetDisplayName }}</h1>
        <p class="-mt-2 mb-4 italic">
          <router-link :to="{ name: 'wc-admin-account-assets', params: { accountId: asset.accountId } }">
            {{ adminAccountsStore.getAccountById(asset.accountId)?.name }}
          </router-link>
        </p>
        <p v-if="asset.portfolioId" class="-mt-2 mb-4">
          <router-link :to="{ name: 'wc-admin-portfolio-assets', params: { portfolioId: asset.portfolioId } }">
            Portfolio {{ asset.portfolioId }}
          </router-link>
        </p>

        <AssetSummaryPanel :asset="asset" :asset-jobs="assetJobs" />

        <div class="mt-4 flex flex-wrap gap-2">
          <WcButton
            text="Rerun Audit"
            icon="wc-carbon:redo"
            :is-disabled="asset.status === AssetStatus.archived"
            size="small"
            @click="handleTriggerAudit" />

          <WcButton
            v-if="asset.status !== AssetStatus.draft && asset.kind !== AssetKind.consumption"
            text="Mark as Draft"
            icon="wc-carbon:pending"
            size="small"
            @click="setAssetStatus(AssetStatus.draft)" />

          <WcButton
            v-if="asset.status !== AssetStatus.ready"
            text="Mark as Ready"
            icon="wc-carbon:checkmark"
            size="small"
            @click="setAssetStatus(AssetStatus.ready)" />

          <WcButton
            v-if="asset.status !== AssetStatus.archived"
            text="Mark as Archived"
            icon="wc-carbon:pending"
            size="small"
            @click="setAssetStatus(AssetStatus.archived)" />

          <WcButton
            v-if="asset.status === AssetStatus.ready"
            text="Mint EACs"
            icon="wc-carbon:stamp"
            :is-disabled="mintingButtonDebounce"
            size="small"
            @click="handleTriggerMinting" />

          <WcButton text="Delete Asset" color="alert" icon="wc-carbon:delete" size="small" @click="deleteAsset" />
        </div>
      </div>

      <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-20 p-1">
        <router-link class="tab" :to="{ name: 'wc-admin-asset-metadata' }">Metadata</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-metadata-edit' }">Edit</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-documents' }">Documents</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-audits' }">Audits</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-versions' }">Versions</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-activity' }">Activity</router-link>
      </nav>

      <router-view :asset="asset" @on-asset-update-state="handleUpdateAssetState"></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { useToast } from "vue-toastification"
import { Icon } from "@iconify/vue"
import type { Asset, AssetJob } from "@/models/asset"
import { AssetStatus, AssetKind, getAssetDisplayName } from "@/models/asset"
import { WcButton } from "@/components/button"
import AssetSummaryPanel from "@/modules/asset/components/AssetSummaryPanel.vue"
import { useAssetService } from "@/services/service-container"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { devicesBatchAlterDevicesAdmin } from "@/client"

const assetService = useAssetService()
const toast = useToast()
const adminAccountsStore = useAdminAccountsStore()

const emit = defineEmits(["on-asset-status-update"])
const props = defineProps<{ assetId: number | null }>()

const asset = ref<Asset | undefined>()
const isLoading = ref<boolean>(true)
const assetJobs = ref<AssetJob[]>([])

const assetDisplayName = computed(() => getAssetDisplayName(asset.value))

const loadAssetData = async (assetId: number) => {
  try {
    asset.value = await assetService.getAssetAdmin(assetId)
    assetJobs.value = await assetService.listAssetJobs(assetId)
  } catch (error) {
    console.error(`There was an error loading asset ${assetId}`, error)
  }
  isLoading.value = false
  mintingButtonDebounce.value = false
}

watchEffect(async () => {
  // The asset list endpoint doesn't include all asset info so we need to fetch
  // additional details from the getAssetAdmin endpoint
  if (props.assetId && !isNaN(props.assetId)) {
    isLoading.value = true
    loadAssetData(props.assetId)
  }
})

const handleTriggerAudit = async () => {
  try {
    await devicesBatchAlterDevicesAdmin({ body: [{ op: "triggerAudit", deviceId: props.assetId! }] })
    toast.success(`Audit triggered for ${assetDisplayName.value}`, {
      timeout: 3000,
    })
    location.reload()
  } catch (error) {
    const message = `There was a problem triggering an audit for ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}

const setAssetStatus = async (status: AssetStatus) => {
  try {
    const response = await devicesBatchAlterDevicesAdmin({
      body: [{ op: "setStatus", deviceId: props.assetId!, status }],
    })
    const updatedAssets = response.data
    // @ts-ignore - the generated API client knows about more asset kinds than the manually made one does
    asset.value = updatedAssets[0]
    emit("on-asset-status-update", status)
    toast.success(`Updated status of ${assetDisplayName.value}`, { timeout: 3000 })
  } catch (error) {
    const message = `There was a problem setting the status for ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}

const deleteAsset = async () => {
  if (asset.value && confirm(`Delete asset '${assetDisplayName.value}' (${asset.value.id})?`)) {
    await assetService.deleteAsset(asset.value.id)
    location.reload()
  }
}

const handleUpdateAssetState = (updatedAsset: Partial<Asset>) => {
  asset.value = {
    ...asset.value,
    ...updatedAsset,
  } as Asset
}

const mintingButtonDebounce = ref(false)
const handleTriggerMinting = async () => {
  if (asset.value && confirm(`Mint EACs for asset '${assetDisplayName.value}' (${asset.value.id})?`)) {
    mintingButtonDebounce.value = true

    await devicesBatchAlterDevicesAdmin({ body: [{ op: "triggerMinting", deviceId: props.assetId! }] })

    setTimeout(() => {
      mintingButtonDebounce.value = false
    }, 5000)
  }
}
</script>

<style scoped lang="scss">
.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-30;
}
</style>
