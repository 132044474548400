import { formatPossibleSnakeCaseStringForDisplay } from "@/utils/formatPossibleSnakeCaseStringForDisplay"
import type { OpenApiStore } from "./openapi.state"
interface BaseComponent {
  type: string
  name: string
  title: string
  description?: string
  required?: boolean
}

interface TextComponent extends BaseComponent {
  type: "text" | "date" | "datetime-local"
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  inputmode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search"
  pattern?: string
  placeholder?: string
}

interface CheckboxComponent extends BaseComponent {
  type: "checkbox"
}

interface SelectOption {
  label: string
  value: string
}

interface SelectComponent extends BaseComponent {
  type: "select"
  options: SelectOption[]
}

export interface FormComponent extends BaseComponent {
  type: "form"
  fields: Component[]
}

type Component = TextComponent | CheckboxComponent | SelectComponent | FormComponent

// Error interface for API validation errors
export interface ApiValidationError {
  loc: string[]
  msg: string
  type?: string
}

function prepareFormField(openapi: OpenApiStore, pointer: string): Component {
  const schema = openapi.dereferenceUri(pointer)
  if (schema === null) {
    return { type: "form", name: "", title: "", fields: [] }
  }

  if ("properties" in schema) {
    const required = schema.required ?? []
    return {
      type: "form",
      name: "",
      title: schema.title,
      description: schema.description,
      fields: Object.keys(schema.properties).map((key) => {
        const subfield = prepareFormField(openapi, `${schema.$id}/properties/${key}`)
        subfield.name = key
        subfield.required = required.includes(key)
        return subfield
      }),
    }
  } else if (schema.type == "string") {
    if (schema.enum !== undefined) {
      return {
        type: "select",
        name: "",
        title: schema.title,
        description: schema.description,
        options: schema.enum.map((value: string) => ({
          label: schema["x-enumDescriptions"]?.[value] ?? formatPossibleSnakeCaseStringForDisplay(value),
          value,
        })),
      }
    } else if (schema.format == "date") {
      return { type: "date", name: "", title: schema.title, description: schema.description }
    } else if (schema.format == "date-time") {
      return { type: "datetime-local", name: "", title: schema.title, description: schema.description }
    } else {
      return {
        type: "text",
        name: "",
        title: schema.title,
        description: schema.description,
        minLength: schema.minLength,
        maxLength: schema.maxLength,
        pattern: schema.pattern,
        placeholder: schema.examples?.[0],
      }
    }
  } else if (schema.type == "integer" || schema.type == "number") {
    return {
      type: "text",
      name: "",
      title: schema.title,
      description: schema.description,
      min: schema.minimum ?? schema.exclusiveMinimum,
      max: schema.maximum ?? schema.exclusiveMaximum,
      inputmode: schema.type == "integer" ? "numeric" : "decimal",
      pattern: schema.type == "integer" ? "[0-9]*" : "(\\+|-)?[0-9]*.[0-9]*",
    }
  } else if (schema.type == "boolean") {
    return { type: "checkbox", name: "", title: schema.title, description: schema.description }
  } else if (schema.type == "array") {
    return { type: "text", name: "", title: schema.title, description: schema.description }
  } else if ("anyOf" in schema) {
    // Hack! We assume the first option is representative of the full range of
    // fields in the rest of the options. We're also making this assumption on
    // the backend in the CSV parsing code.
    const field = prepareFormField(openapi, `${pointer}/anyOf/0`)

    if (field.type == "form") {
      // Since the first subform might have required fields that aren't required
      // in the other subforms, we need to remove the required attribute and let
      // the backend determine if the field is truly required.
      for (const f of field.fields) {
        f.required = false
      }
    }

    if (schema.title !== undefined) {
      field.title = schema.title
    }

    if (schema.description !== undefined) {
      field.description = schema.description
    }

    if (field.type === "text" && schema?.examples?.[0] !== undefined) {
      field.placeholder = schema.examples[0]
    }

    return field
  } else {
    throw Error("unexpected form type")
  }
}

export function prepareForm(openapi: OpenApiStore, pointer: string): FormComponent {
  return prepareFormField(openapi, pointer) as FormComponent
}

const EMPTY_FORM = { type: "form", name: "", title: "", fields: [] } as FormComponent

// Find a particular form field by name and create a form with just that field
export function extractSingleField(form: FormComponent, fieldName: string): FormComponent {
  const field = form.fields.find((f) => f.name === fieldName)
  if (!field) {
    return EMPTY_FORM
  }

  return {
    type: "form",
    name: form.name,
    title: form.title,
    description: form.description,
    fields: [field],
  }
}

// Parse API error responses into field-specific and general errors
export function parseApiErrors(error: any): { fieldErrors: ApiValidationError[]; generalErrors: string[] } {
  const fieldErrors: ApiValidationError[] = []
  const generalErrors: string[] = []

  const errorDetails = error?.detail
  if (Array.isArray(errorDetails)) {
    errorDetails.forEach((detail: any) => {
      if (detail.loc && Array.isArray(detail.loc)) {
        // Check if this is a field-specific error or a form-level validation error
        if (detail.loc.length > 2) {
          // Field-specific error (has path to specific field)
          fieldErrors.push(detail)
        } else {
          // Form-level validation error (only has path to the form object)
          generalErrors.push(detail.msg || "Unknown error")
        }
      } else {
        // General error without location
        generalErrors.push(detail.msg || "Unknown error")
      }
    })
  } else if (error?.detail?.length) {
    generalErrors.push(error.detail)
  } else {
    // Fallback for unexpected error format
    generalErrors.push("There was a problem submitting the form. Please try again.")
  }

  return { fieldErrors, generalErrors }
}
