import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue"
import { onBeforeRouteLeave } from "vue-router"

const CONFIRM_UNSAVED_CHANGES_MESSAGE = "You have unsaved changes. Are you sure you want to leave this page?"

// Takes two refs, watches for changes against the initial state, and prompts
// the user when trying to navigate away from the page
export function useWarnForUnsavedChanges(initialValue: any, currentValue: any) {
  const hasFormChanged = ref<boolean>(false)
  const isNavigatingAway = ref<boolean>(false)

  watch(
    () => currentValue,
    (newValue) => {
      try {
        const newValueStr = JSON.stringify(newValue.value || newValue)
        const initialValueStr = JSON.stringify(initialValue.value || initialValue)
        hasFormChanged.value = newValueStr !== initialValueStr
      } catch (error) {
        console.error("Error comparing values for unsaved changes:", error)
        // If we can't compare the values, assume there are not changes so we
        // don't unnecessarily block the user from leaving the page
        hasFormChanged.value = false
      }
    },
    { deep: true }
  )

  onBeforeRouteLeave((_to, _from, next) => {
    if (isNavigatingAway.value) {
      next()
      return
    }

    if (hasFormChanged.value) {
      const confirmed = window.confirm(CONFIRM_UNSAVED_CHANGES_MESSAGE)
      if (confirmed) {
        isNavigatingAway.value = true
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  })

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (hasFormChanged.value) {
      e.preventDefault()
      return CONFIRM_UNSAVED_CHANGES_MESSAGE
    }
  }

  onBeforeMount(() => {
    window.addEventListener("beforeunload", handleBeforeUnload)
  })

  onBeforeUnmount(() => {
    window.removeEventListener("beforeunload", handleBeforeUnload)
  })

  const resetUnsavedChanges = () => {
    hasFormChanged.value = false
    isNavigatingAway.value = false
  }

  return {
    resetUnsavedChanges,
  }
}
