<template>
  <div class="max-w-[1144px]">
    <h2 class="text-subheading-large-bold mb-4">Asset registration</h2>
    <p class="text-body-2 mb-3">Tell Aristotle a little about your project.</p>
    <div class="text-caption mb-12 text-hint">*indicates required field</div>

    <InlineLoading v-if="!componentPointer || !formSchema" />

    <div v-else>
      <form ref="assetFormRef" class="flex flex-col space-y-6" @submit.prevent="handleSubmit">
        <!-- Project Name and Internal ID fields for all methodology assets -->
        <div class="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2">
          <div v-for="fieldName in ['name', 'customId']" :key="fieldName">
            <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, fieldName)" :errors="fieldErrors" />
          </div>
        </div>

        <h3 class="text-subheading-2 mb-8">Required fields</h3>

        <!-- Special Location field -->
        <div class="grid grid-cols-1 items-end gap-6 xl:grid-cols-2">
          <div>
            <label class="text-body-3 pl-0 text-black" for="asset-location"> Location * </label>
            <WcAutocompleteAddress
              id="asset-location"
              :address="formatLocationAddress(formValue.location)"
              data-cy="input-asset-location"
              input-class="!py-2"
              @set-address="setLocationFromAddress" />
          </div>
        </div>

        <!-- Special fields for Electrification methodology assets -->
        <ElectrificationRequiredFields
          v-if="selectedMethodology?.kind === 'electrification_automatic'"
          v-model="formValue"
          :form-schema="formSchema"
          :field-errors="fieldErrors" />

        <!-- Required fields for all other methodology assets -->
        <div v-else class="grid grid-cols-1 items-end gap-6 lg:grid-cols-2 xl:grid-cols-2">
          <WcOpenApiFormFields v-model="formValue" :form="requiredFormSchema" :hide="HIDDEN_FORM_FIELDS" :errors="fieldErrors" />
        </div>

        <div v-if="generalErrors.length > 0" class="mb-4 rounded-md border border-error bg-error-light p-4">
          <h5 class="text-subheading-2 mb-2 text-error">Form Validation Errors</h5>
          <ul class="list-disc space-y-1 pl-5">
            <li v-for="generalError in generalErrors" :key="generalError" class="text-error">{{ generalError }}</li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import type { Meter } from "@/client/types.gen"
import { useOpenApiStore } from "@/components/form/openapi.state"
import { ApiValidationError, extractSingleField, prepareForm, parseApiErrors } from "@/components/form/WcOpenApiForm.utils"
import WcOpenApiFormFields from "@/components/form/WcOpenApiFormFields.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import WcAutocompleteAddress from "@/components/WcAutocompleteAddress.vue"
import type { Methodology } from "@/modules/asset/asset.service"
import ElectrificationRequiredFields from "@/modules/asset/components/fields/ElectrificationRequiredFields.vue"
import { formatAddress } from "@/utils/formatAddress"

const HIDDEN_FORM_FIELDS = ["kind", "groupId", "groupName", "location", "meterIds"]

type AssetData = Record<string, any>

const props = defineProps<{
  modelValue: AssetData
  selectedMethodology: Methodology | null
  selectedMeters: Meter[]
  error: any
}>()

const emit = defineEmits<{
  (e: "update:modelValue", value: AssetData): void
  (e: "submit", value: AssetData): void
}>()

const openApiStore = useOpenApiStore()
const assetFormRef = ref<HTMLFormElement | null>(null)
const fieldErrors = ref<ApiValidationError[]>([])
const generalErrors = ref<string[]>([])

const formValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value)
  },
})

const componentPointer = computed(() => {
  if (!props.selectedMethodology) {
    return null
  }

  const discriminator = openApiStore.dereferenceUri("#/paths/~1devices/post/requestBody/content/application~1json/schema/discriminator")
  if (!discriminator) {
    return null
  }
  return discriminator.mapping[props.selectedMethodology.kind]
})

const formSchema = computed(() => {
  if (!componentPointer.value) {
    return null
  }
  const baseSchema = prepareForm(openApiStore, componentPointer.value)
  return baseSchema
})

const requiredFormSchema = computed(() => {
  if (!formSchema.value?.fields) {
    return {
      type: "form" as const,
      name: "",
      title: "",
      fields: [],
    }
  }

  return {
    ...formSchema.value,
    fields: formSchema.value.fields.filter((field) => field.required),
  }
})

const setLocationFromAddress = (address: any) => {
  const newValue = {
    ...props.modelValue,
    location: {
      street: address.street,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country || "US",
    },
  }
  emit("update:modelValue", newValue)
}

const formatLocationAddress = (location: any) => {
  if (!location) {
    return ""
  }
  const formattedAddress = formatAddress({
    street_1: location.street,
    city: location.city,
    state_province: location.state,
    postal_code: location.postalCode,
    country: location.country,
  })

  return formattedAddress !== "—" ? formattedAddress : ""
}
const validateFormData = (form: HTMLFormElement): boolean => {
  if (form && !form.checkValidity()) {
    form.reportValidity()
    return false
  }

  return true
}

const handleSubmit = (event: Event) => {
  const form = event.target as HTMLFormElement

  if (!validateFormData(form)) {
    return
  }
  emit("submit", formValue.value)
}

// Watch for error changes and update the local error state
watch(
  () => props.error,
  () => {
    const parsedErrors = props.error ? parseApiErrors(props.error) : { fieldErrors: [], generalErrors: [] }
    fieldErrors.value = parsedErrors.fieldErrors
    generalErrors.value = parsedErrors.generalErrors
  },
  { immediate: true, deep: true }
)

defineExpose({
  form: assetFormRef,
})
</script>
