<template>
  <WcModal v-model:is-open="isOpen" size="full" header="Measurement & Verification Details">
    <div class="text-body-2 text-black">
      <section class="bg-darktone-lightbackground py-12">
        <h1 class="wc-page-container text-subheading-large-bold mb-12 text-black">
          Showing a summary for audit {{ auditTrailId }} {{ asset && `(${getAssetDisplayName(asset)})` }}
        </h1>
        <div class="wc-page-container flex w-full flex-col justify-between gap-5 lg:flex-row lg:items-stretch">
          <MAndVSummaryMetadata :asset="asset" eac-count="—" class="grid-cols-3 sm:grid-cols-5 lg:flex-1" :eac-id-range="null" :story="story" />
          <div class="text-right max-lg:h-[314px] lg:min-h-[338px] lg:flex-1">
            <WcMap :locations="asset?.coordinates ? [asset.coordinates] : []" :show-zoom-buttons="false" class="h-full rounded-xl" />
          </div>
        </div>
      </section>
      <section class="wc-page-container py-12 text-black">
        <InlineLoading v-if="isLoading" />
        <MAndVSummary v-else :methodology-description="methodologyDescription" />
      </section>
    </div>
  </WcModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import "mapbox-gl/dist/mapbox-gl.css"
import WcMap from "@/components/WcMap/WcMap.vue"
import WcModal from "@/components/WcModal.vue"
import type { Asset } from "@/models/asset"
import { getAssetDisplayName } from "@/models/asset"
import { MethodologyDescription } from "@/models/audit"
import type { Story } from "@/models/story"
import { useAssetService, useStoryService } from "@/services/service-container"
import MAndVSummaryMetadata from "./components/MAndVSummaryMetadata.vue"
import MAndVSummary from "./components/MAndVSummary.vue"

const assetService = useAssetService()
const storyService = useStoryService()

const isOpen = ref<boolean>(false)
const auditTrailId = ref<number | null>(null)
const asset = ref<Asset | null>(null)
const story = ref<Story | undefined>()
const isLoading = ref<boolean>(false)
const methodologyDescription = ref<MethodologyDescription | null>(null)

const loadAuditTrail = async (auditTrailId: number) => {
  isLoading.value = true
  methodologyDescription.value = null
  try {
    methodologyDescription.value = await assetService.getAuditTrail(auditTrailId)
  } catch {
    // Likely a 404, which means there's no M&V description for this asset
    methodologyDescription.value = null
  } finally {
    isLoading.value = false
  }
}

watch(
  () => asset.value,
  async (newAsset) => {
    if (newAsset) {
      story.value = undefined

      try {
        if (newAsset?.storyId) {
          story.value = await storyService.getStory(newAsset.storyId)
        }
      } catch (error) {
        console.error("There was an error loading the story for M&V metadata", error)
      }
    }
  },
  { immediate: true }
)

const handleUpdateIsOpen = async (newValue: boolean) => {
  isOpen.value = newValue
}

const openModal = async (newAuditTrailId: number, newAsset: Asset) => {
  loadAuditTrail(newAuditTrailId)
  auditTrailId.value = newAuditTrailId
  asset.value = newAsset
  handleUpdateIsOpen(true)
}

const closeModal = () => handleUpdateIsOpen(false)

defineExpose({ openModal, closeModal })
</script>
