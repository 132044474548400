<template>
  <WcModal :is-open="true" size="full" header="Measurement & Verification Details" @update:is-open="(value) => !value && $emit('modal-reject')">
    <div v-if="portfolioDevice" class="text-body-2 text-black">
      <section class="bg-darktone-lightbackground py-12">
        <h1 class="wc-page-container text-subheading-large-bold mb-12 text-black">
          {{ `${eacCount} EACs generated by ${portfolioDevice.story.name}` }}
        </h1>
        <div class="wc-page-container flex w-full flex-col justify-between gap-5 lg:flex-row lg:items-stretch">
          <MAndVSummaryMetadata
            :eac-count="eacCount"
            :asset="portfolioDevice"
            :story="portfolioDevice.story"
            :eac-id-range="null"
            class="grid-cols-3 sm:grid-cols-5 lg:flex-1" />
          <div class="text-right max-lg:h-[314px] lg:min-h-[338px] lg:flex-1">
            <WcMap
              :locations="[
                {
                  latitude: String(portfolioDevice.coordinates.latitude),
                  longitude: String(portfolioDevice.coordinates.longitude),
                },
              ]"
              :show-zoom-buttons="false"
              class="h-full rounded-xl" />
          </div>
        </div>
      </section>
      <section class="wc-page-container py-12 text-black">
        <InlineLoading v-if="isLoading" />
        <MAndVSummary v-else :methodology-description="methodologyDescription ?? null" />
      </section>
    </div>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import "mapbox-gl/dist/mapbox-gl.css"
import { auditsGetLatestDeviceAuditTrailPublic } from "@/client/sdk.gen"
import { MethodologyDescriptionOut } from "@/client/types.gen"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import WcMap from "@/components/WcMap/WcMap.vue"
import WcModal from "@/components/WcModal.vue"
import { PortfolioDevice } from "@/client/types.gen"
import MAndVSummary from "./components/MAndVSummary.vue"
import MAndVSummaryMetadata from "./components/MAndVSummaryMetadata.vue"

const props = defineProps<{ portfolioDevice: PortfolioDevice }>()
defineEmits<{
  (e: "modal-reject"): void
  (e: "modal-resolve", value: { assetIds: number[] }): void
}>()

const isLoading = ref<boolean>(true)
const methodologyDescription = ref<MethodologyDescriptionOut>()

const loadMethodologyDescription = async () => {
  if (props.portfolioDevice.id) {
    isLoading.value = true
    try {
      const result = await auditsGetLatestDeviceAuditTrailPublic({ query: { deviceId: props.portfolioDevice.id } })
      methodologyDescription.value = result.data ?? undefined
    } catch (error) {
      console.error("There was an error loading the portfolio device M&V description", error)
    }
    isLoading.value = false
  }
}

onMounted(async () => {
  loadMethodologyDescription()
})

const eacCount = computed<string>(() => {
  return new Intl.NumberFormat("en-US").format(Math.abs(props.portfolioDevice.allocation.allocatedEacs ?? 0))
})
</script>
